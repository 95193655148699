import Markdown, { Components, ExtraProps } from 'react-markdown';
import { HTMLProps, DetailedHTMLFactory, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { getDOMProps } from 'app/shared/utils/react';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import { U21ExternalLinkModal } from 'app/shared/u21-ui/components/display/U21ExternalLinkModal';

export interface U21MarkdownProps extends HTMLProps<HTMLDivElement> {
  children: string;
}

export const U21Markdown = (props: U21MarkdownProps) => {
  const { children, ...rest } = props;
  return (
    <Markdown components={COMPONENTS} {...getDOMProps(rest)}>
      {children}
    </Markdown>
  );
};

const COMPONENTS: Components = {
  a: (props: HTMLProps<HTMLAnchorElement> & ExtraProps) => {
    const { href, children } = props;
    if (!href) {
      return null;
    }
    return (
      <U21ExternalLinkModal url={href}>
        {({ onClick }) => (
          <U21Button color="primary" onClick={onClick} variant="text">
            {children}
          </U21Button>
        )}
      </U21ExternalLinkModal>
    );
  },
  h1: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { children, node, ...rest } = props;
    return (
      <H1 variant="h4" {...getDOMProps(rest)}>
        {children}
      </H1>
    );
  },
  h2: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { children, node, ...rest } = props;
    return (
      <H2 variant="h5" {...getDOMProps(rest)}>
        {children}
      </H2>
    );
  },
  h3: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { node, ...rest } = props;
    return <H3 variant="h6" {...getDOMProps(rest)} />;
  },
  h4: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { node, ...rest } = props;
    return <H456 variant="subtitle1" {...getDOMProps(rest)} />;
  },
  h5: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { node, ...rest } = props;
    return <H456 variant="subtitle1" {...getDOMProps(rest)} />;
  },
  h6: (props: HTMLProps<HTMLHeadingElement> & ExtraProps) => {
    const { node, ...rest } = props;
    return <H456 variant="subtitle1" {...getDOMProps(rest)} />;
  },
  p: (
    props: DetailedHTMLFactory<
      HTMLAttributes<HTMLHeadingElement>,
      HTMLHeadingElement
    > &
      ExtraProps,
  ) => {
    const { node, ...rest } = props;
    return <P variant="body2" {...getDOMProps(rest)} />;
  },
  code: (
    props: DetailedHTMLFactory<HTMLAttributes<HTMLElement>, HTMLElement> &
      ExtraProps,
  ) => {
    const { node, ...rest } = props;
    return <Code inline variant="inherit" {...rest} />;
  },
  pre: (props: HTMLProps<HTMLPreElement> & ExtraProps) => {
    const { children, node, ...rest } = props;
    return (
      <Pre variant="body2" {...getDOMProps(rest)}>
        {children}
      </Pre>
    );
  },
  hr: (
    props: DetailedHTMLFactory<HTMLAttributes<HTMLHRElement>, HTMLElement> &
      ExtraProps,
  ) => {
    const { node, ...rest } = props;
    return <HR horizontal {...getDOMProps(rest)} />;
  },
  ul: (
    props: DetailedHTMLFactory<
      HTMLAttributes<HTMLUListElement>,
      HTMLUListElement
    > &
      ExtraProps,
  ) => {
    const { node, ...rest } = props;
    return <UL {...rest} />;
  },
  ol: (
    props: DetailedHTMLFactory<
      HTMLAttributes<HTMLUListElement>,
      HTMLUListElement
    > &
      ExtraProps,
  ) => {
    const { node, ...rest } = props;
    return <OL {...rest} />;
  },
  li: (props: HTMLProps<HTMLLIElement> & ExtraProps) => {
    const { children, node, ...rest } = props;
    return (
      <li {...getDOMProps(rest)}>
        <U21Typography variant="body2">{children}</U21Typography>
      </li>
    );
  },
};

const H1 = styled(U21Typography)`
  margin-bottom: 16px;
`;

const H2 = styled(U21Typography)`
  margin-top: 16px;
  margin-bottom: 12px;
`;

const H3 = styled(U21Typography)`
  margin-top: 12px;
  margin-bottom: 8px;
`;

const H456 = styled(U21Typography)`
  margin-top: 12px;
  margin-bottom: 8px;
`;

const Code = styled(U21Typography)`
  background-color: ${(props) => props.theme.palette.background.neutral};
  border-radius: 4px;
  padding: 0 4px;
  white-space: pre;
`;

const P = styled(U21Typography)`
  margin-bottom: 16px;
`;

const Pre = styled(U21Typography)`
  background-color: ${(props) => props.theme.palette.background.neutral};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
`;

const HR = styled(U21Divider)`
  margin: 16px 0;
`;

const UL = styled.ul`
  padding-left: 24px;
  margin-bottom: 16px;

  li + li {
    margin-top: 4px;
  }

  // nested lists should not have margin bottom
  ol,
  ul {
    margin-bottom: 0;
  }
`;

const OL = styled.ol`
  padding-left: 24px;
  margin-bottom: 16px;

  li + li {
    margin-top: 4px;
  }

  // nested lists should not have margin bottom
  ol,
  ul {
    margin-bottom: 0;
  }
`;
