import { createSelector } from 'reselect';

// Constants
import {
  DEFAULT_UNASSIGNED_TO_RETRIEVE_COUNT,
  INITIAL_ALERT,
} from 'app/modules/alerts/constants';

// Selectors
import {
  selectAgent,
  selectAlertActionTriggers,
  selectAgentAlertAssignmentLimit,
  selectHasReadAssignmentsPermissions,
  selectHasReadAgentsPermissions,
  selectHasEditAlertsPermission,
} from 'app/modules/session/selectors';
import {
  selectAlertEnabledFeatures,
  selectLumosConfig,
} from 'app/modules/orgSettings/selectors';
import { selectQueue, selectLoadingQueue } from 'app/modules/queues/selectors';

import {
  AlertsActionTypes,
  AlertsState,
  RetrieveAlertHistogramPayload,
} from 'app/modules/alerts/models';

// Types
import {
  AssociatedEntitiesResponse,
  AssociatedInstrumentsResponse,
} from 'app/modules/alerts/types';

// Helpers
import { shouldRenderActionTrigger } from 'app/modules/alerts/helpers';
import { formatEntityOptions } from 'app/modules/search/helpers';
import { selectLinkAnalysisFilterValue } from 'app/modules/filtersOld/selectors';

export const selectAlerts = (state: RootState): AlertsState['alerts'] => {
  return state.alerts.alerts || [];
};

export const selectAlertsCount = (state: RootState) => {
  return state.alerts.alertsCount;
};

export const selectAlertsEntitiesCount = (state: RootState) => {
  return state.alerts.alertsEntitiesCount;
};

export const selectAlertsInstrumentsCount = (state: RootState) => {
  return state.alerts.alertsInstrumentsCount;
};

export const selectMyAlertsStats = (state: RootState) => {
  return state.alerts.myAlertsStats;
};

export const selectAlert = (state: RootState) => {
  return state?.alerts?.alert || INITIAL_ALERT;
};

export const selectAlertEvents = (state: RootState) => {
  return state.alerts.alert?.events || [];
};

export const selectAlertHistogramPayload = createSelector(
  selectAlert,
  // TODO: how else can we get the selected entity?
  selectLinkAnalysisFilterValue('selectedEntity'),
  selectLinkAnalysisFilterValue('showCurrentAlert'),
  ({ id }, selectedEntity, showCurrentAlert): RetrieveAlertHistogramPayload => {
    if (showCurrentAlert) {
      return {
        alert_id: id,
      };
    }
    return {
      entity_id: selectedEntity,
    };
  },
);

export const selectAlertHistogram = (state: RootState) =>
  state.alerts.histogram;

export const selectAlertEntities = createSelector(
  selectAlert,
  ({ entities }) => entities || [],
);

export const selectAlertPaginatedEntities = (
  state: RootState,
): AssociatedEntitiesResponse => {
  return (
    state.alerts.alert.paginatedEntities || {
      entities: [],
      count: 0,
    }
  );
};

export const selectAlertEntityOptions = createSelector(
  selectAlertEntities,
  (entities) => formatEntityOptions(entities, 'unit21_id'),
);

export const selectAlertPaginatedInstruments = (
  state: RootState,
): AssociatedInstrumentsResponse => {
  return (
    state.alerts.alert.paginatedInstruments || {
      instruments: [],
      count: 0,
    }
  );
};

export const selectAlertQueues = (state: RootState) => {
  return state.alerts.alertQueues || [];
};

export const selectAgentAllowedQueues = createSelector(
  selectAlertQueues,
  selectAgent,
  (queues, agent) => {
    const agentTeamIds = agent.teams.map((team) => team.id);
    return queues.filter((queue) => {
      return (
        queue.org_default_queue ||
        queue.teams.some((team) => agentTeamIds.includes(team.id))
      );
    });
  },
);

export const selectIsIntuitAlert = createSelector(
  selectAlertEnabledFeatures,
  (enabledFeatures) => {
    // These features are unique to Intuit
    return (
      enabledFeatures.includes('ASSOCIATED_USERS') &&
      enabledFeatures.includes('ASSOCIATED_BUSINESSES')
    );
  },
);

// Making a separate selector so we can easily
// move the audit_trail out of AlertDetails
export const selectAlertAuditTrail = (state: RootState) =>
  state.alerts.alert.audit_trail || [];

// Loading
export const selectAlertActionEventsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_ACTION_EVENTS]);

export const selectAlertsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERTS]);

export const selectRetrieveAlertEntitiesLoading = (state: RootState) => {
  return Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_ENTITIES]);
};

export const selectAlertTransactionsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_TRANSACTIONS]);

export const selectAlertEntitiesLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_ENTITIES]);

export const selectAlertInstrumentsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_INSTRUMENTS]);

export const selectAlertLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT]) ||
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_DETAILS]);

export const selectAlertComponentStatusLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.CHANGE_ALERT_COMPONENT_STATUS]);

export const selectReassignAlertsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.REASSIGN_ALERTS]);

export const selectRequeueAlertsLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.REQUEUE_ALERTS]);

export const selectAlertAuditTrailLoading = (state: RootState) => {
  return Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_AUDIT_TRAIL]);
};

export const selectCreateAlertLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.CREATE_ALERT]);

export const selectEditAlertLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.EDIT_ALERT]);

export const selectTriggerAlertActionLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.TRIGGER_ALERT_ACTION]);

export const selectAlertActionEvents = createSelector(
  selectAlert,
  (alert) =>
    alert.action_events || {
      count: 0,
      events: [],
    },
);

export const selectFilteredActionTriggers = createSelector(
  selectAlertActionTriggers,
  selectAlert,
  (actionTriggers, alert) =>
    actionTriggers.filter((actionTrigger) =>
      shouldRenderActionTrigger(actionTrigger, alert),
    ),
);

export const selectAlertAssociatedEntities = createSelector(
  selectAlert,
  ({ entities }) => entities || [],
);

export const selectAlertHistogramLoading = (state: RootState) =>
  Boolean(state.loading[AlertsActionTypes.RETRIEVE_ALERT_HISTOGRAM]);

export const selectHasValidAlert = createSelector(
  selectAlert,
  selectAlertLoading,
  ({ id }, alertLoading) => id !== -1 && !alertLoading,
);

export const selectNumUnassignedToRetrieve = createSelector(
  selectLumosConfig,
  (lumosConfig): number =>
    lumosConfig.unassigned_to_retrieve_count ||
    DEFAULT_UNASSIGNED_TO_RETRIEVE_COUNT,
);

export const selectGetMoreAlertsDisabled = createSelector(
  selectAgentAlertAssignmentLimit,
  selectMyAlertsStats,
  (assignmentLimit, alertsStats) =>
    assignmentLimit > 0 && alertsStats.open_alerts_count >= assignmentLimit,
);

export const selectHasValidAlertQueue = createSelector(
  selectQueue,
  selectLoadingQueue,
  ({ id }, loading): boolean => id > 0 && !loading,
);

// This logic is based on what's defined in the API
// patronus/server/alert_queues/alert_queue.py::get_unassigned_alerts
export const selectCanGetNewAlerts = createSelector(
  selectAgent,
  selectQueue,
  selectHasValidAlertQueue,
  (agent, alertQueue, hasValidAlertQueue): boolean => {
    if (!hasValidAlertQueue) {
      return false;
    }

    if (alertQueue.org_default_queue) {
      return true;
    }

    const agentTeamsIDs = {};
    agent.teams.forEach((team) => {
      agentTeamsIDs[team.id] = true;
    });

    // Check if there's any overlap
    return alertQueue.teams.some((team) => agentTeamsIDs[team.id]);
  },
);

export const selectHasReassignAlertPermission = createSelector(
  selectHasReadAgentsPermissions,
  selectHasEditAlertsPermission,
  (hasReadAgentsPermission, hasEditAlertsPermission) => {
    return hasReadAgentsPermission && hasEditAlertsPermission;
  },
);

export const selectHasRequeueAlertPermission = createSelector(
  selectHasReadAssignmentsPermissions,
  selectHasEditAlertsPermission,
  (hasReadAssignmentsPermission, hasEditAlertsPermission) => {
    return hasReadAssignmentsPermission && hasEditAlertsPermission;
  },
);
