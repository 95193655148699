import { useSelector } from 'react-redux';
import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import {
  U21SelectSearch,
  U21SelectSearchProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { IconCreditCard } from '@u21/tabler-icons';

// Selectors
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';

// Constants
import { INSTRUMENTS_TYPE_OPTIONS } from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

// Helpers
import { formatInstrumentOptions } from 'app/modules/search/helpers';
import { uniqBy } from 'lodash';
import { InstrumentSearchRequest } from 'app/modules/search/models';
import { useQueryClient } from '@tanstack/react-query';
import {
  searchInstruments,
  useSearchInstruments,
} from 'app/modules/search/queries/useSearchInstruments';
import { SEARCH_QUERY_KEYS } from 'app/modules/search/queries/keys';

export interface U21InstrumentSearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: string[], e: SyntheticEvent) => void;
  value?: string[];
  searchProps?: Omit<
    U21SelectSearchProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
  additionalOptions?: U21SelectOptionProps[];
}

export const U21InstrumentSearch: FC<U21InstrumentSearchProps> = ({
  disabled,
  error,
  clearable = true,
  onChange,
  value = [],
  searchProps = {},
  additionalOptions = [],
  ...rest
}) => {
  const queryClient = useQueryClient();
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const [search, setSearch] = useState<string | undefined>('');
  const type = INSTRUMENTS_TYPE_OPTIONS[0].value;

  const payload = useMemo(() => {
    return {
      limit: INFINITE_SCROLL_LIMIT,
      offset: OFFSET_DEFAULT,
      phrase: search || '',
      type,
    };
  }, [search, type]);

  const { instruments, isFetching } = useSearchInstruments(payload);

  const options = useMemo(() => {
    return uniqBy(
      [...formatInstrumentOptions(instruments, type), ...additionalOptions],
      'value',
    );
  }, [additionalOptions, instruments, type]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadInstrumentsPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        const searchPayload: InstrumentSearchRequest = {
          phrase: '',
          limit: missingValues.length,
          offset: OFFSET_DEFAULT,
          type: 'external_id',
          external_ids: missingValues,
        };

        queryClient.prefetchQuery({
          queryKey: SEARCH_QUERY_KEYS.searchInstruments(searchPayload),
          queryFn: () => searchInstruments(searchPayload),
        });
      }
    }
  }, [hasReadInstrumentsPermissions, options, queryClient, value]);

  if (!hasReadInstrumentsPermissions) {
    return null;
  }

  return (
    <StyledU21SelectSearch
      disabled={disabled}
      error={error}
      clearable={clearable}
      limitTags={1}
      loading={isFetching}
      multi
      onChange={onChange}
      options={options}
      onSearch={setSearch}
      placeholder="Any"
      startIcon={<IconCreditCard />}
      value={value}
      {...rest}
      {...searchProps}
    />
  );
};

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;
