import { GetAlertsPayload } from 'app/modules/alerts/models';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export interface RemovedExpensiveOperationsType {
  sortColumn:
    | AlertsByEntityExpensiveSorting
    | AlertsByEventExpensiveSorting
    | undefined;
  filters: ExpensiveAlertFiltersType[];
}

export const EXPENSIVE_ALERT_FILTERS = [
  'custom_data_filters',
  'entity_ids',
  'sort_column',
] as const satisfies DeepReadonly<(keyof GetAlertsPayload)[]>;
export type ExpensiveAlertFiltersType =
  (typeof EXPENSIVE_ALERT_FILTERS)[number];

export enum AlertGroupByObject {
  ENTITY = 'ENTITY',
  EVENT = 'EVENT',
  NONE = 'NONE',
  INSTRUMENT = 'INSTRUMENT',
}

export enum AlertsByObjectSorting {
  OPEN_ALERTS = 'OPEN_ALERTS',
  TOTAL_ALERTS = 'TOTAL_ALERTS',
  DEADLINE = 'DEADLINE',
  ALERT_SCORE = 'ALERT_SCORE',
  ALERT_AMOUNT_VOLUME = 'ALERT_AMOUNT_VOLUME',
}

export enum AlertsByEntityExpensiveSorting {
  FLAGGED_AMOUNTS = 'FLAGGED_AMOUNTS',
}

export enum AlertsByEventExpensiveSorting {
  FLAGGED_ENTITIES = 'FLAGGED_ENTITIES',
}

export type AlertsByEntitySortingOptionsType =
  | AlertsByObjectSorting
  | AlertsByEntityExpensiveSorting;

type AlertsByEventSortingOptionsType =
  | AlertsByObjectSorting
  | AlertsByEventExpensiveSorting;

export type AlertsByInstrumentSortingOptionsType = AlertsByObjectSorting;

interface AlertsByEntityExpensivePayload extends GetAlertsPayload {
  investigation_object: AlertGroupByObject.ENTITY;
  sort_column: AlertsByEntitySortingOptionsType;
}

interface AlertsByEntityPayload
  extends Omit<AlertsByEntityExpensivePayload, ExpensiveAlertFiltersType> {
  sort_column: AlertsByObjectSorting;
}

interface AlertsByEventExpensivePayload extends GetAlertsPayload {
  investigation_object: AlertGroupByObject.EVENT;
  sort_column: AlertsByEventSortingOptionsType;
}

interface AlertsByEventPayload
  extends Omit<AlertsByEventExpensivePayload, ExpensiveAlertFiltersType> {
  sort_column: AlertsByObjectSorting;
}

interface AlertsByInstrumentPayload extends GetAlertsPayload {
  investigation_object: AlertGroupByObject.INSTRUMENT;
  sort_column: AlertsByInstrumentSortingOptionsType;
}

export type GetAlertsGroupedByObjectExpensivePayload =
  | AlertsByEntityExpensivePayload
  | AlertsByEventExpensivePayload;

export type GetAlertsGroupedByObjectPayload =
  | AlertsByEntityPayload
  | AlertsByEventPayload
  | AlertsByInstrumentPayload;

export const ALERT_GROUP_BY_OPTIONS: {
  value: AlertGroupByObject;
  text: string;
}[] = [
  {
    value: AlertGroupByObject.NONE,
    text: 'None',
  },
  {
    value: AlertGroupByObject.ENTITY,
    text: 'Entity',
  },
  {
    value: AlertGroupByObject.INSTRUMENT,
    text: 'Instrument',
  },
];

const ALERT_SORT_BY_OPTIONS: U21SelectOptionProps<AlertsByObjectSorting>[] = [
  {
    value: AlertsByObjectSorting.TOTAL_ALERTS,
    text: 'Total alerts',
  },
  {
    value: AlertsByObjectSorting.OPEN_ALERTS,
    text: 'Open alerts',
  },
  {
    value: AlertsByObjectSorting.DEADLINE,
    text: 'Due date',
  },
  {
    value: AlertsByObjectSorting.ALERT_SCORE,
    text: 'Alert score',
  },
  {
    value: AlertsByObjectSorting.ALERT_AMOUNT_VOLUME,
    text: 'Alert amount',
  },
];

export const ALERTS_BY_ENTITY_SORT_BY_OPTIONS: U21SelectOptionProps<AlertsByEntitySortingOptionsType>[] =
  [...ALERT_SORT_BY_OPTIONS];

export const ALERTS_BY_INSTRUMENT_SORT_BY_OPTIONS: U21SelectOptionProps<AlertsByInstrumentSortingOptionsType>[] =
  [...ALERT_SORT_BY_OPTIONS];
