import user from '@tabler/icons/outline/user.svg?raw';
import home from '@tabler/icons/outline/home.svg?raw';
import fingerprint from '@tabler/icons/outline/fingerprint.svg?raw';
import mail from '@tabler/icons/outline/mail.svg?raw';
import creditCard from '@tabler/icons/outline/credit-card.svg?raw';
import wifi from '@tabler/icons/outline/wifi.svg?raw';
import phone from '@tabler/icons/outline/phone.svg?raw';
import license from '@tabler/icons/outline/license.svg?raw';
import number from '@tabler/icons/outline/number.svg?raw';
import bank from '@tabler/icons/outline/building-bank.svg?raw';
import building from '@tabler/icons/outline/building.svg?raw';
import calendar from '@tabler/icons/outline/calendar.svg?raw';
import mapPin from '@tabler/icons/outline/map-pin.svg?raw';
import tag from '@tabler/icons/outline/tag.svg?raw';
import filter from '@tabler/icons/outline/filter.svg?raw';
import filterOff from '@tabler/icons/outline/filter-off.svg?raw';
import plus from '@tabler/icons/outline/plus.svg?raw';
import minus from '@tabler/icons/outline/minus.svg?raw';
import folders from '@tabler/icons/outline/folders.svg?raw';
import foldersOff from '@tabler/icons/outline/folders-off.svg?raw';
import ssn from 'app/shared/svg/ssn.svg?raw';
import braces from '@tabler/icons/outline/braces.svg?raw';

import { encodeSvg } from 'app/shared/components/Graphs/utils';

// standard SVGs (24x24) – meant for standard nodes (32x32)
export const User = encodeSvg(user);
export const Home = encodeSvg(home);
export const Fingerprint = encodeSvg(fingerprint);
export const Mail = encodeSvg(mail);
export const Wifi = encodeSvg(wifi);
export const Phone = encodeSvg(phone);
export const License = encodeSvg(license);
export const Number = encodeSvg(number);
export const Ssn = encodeSvg(ssn);
export const CreditCard = encodeSvg(creditCard);
export const Bank = encodeSvg(bank);
export const Building = encodeSvg(building);
export const Calendar = encodeSvg(calendar);
export const MapPin = encodeSvg(mapPin);
export const Tag = encodeSvg(tag);
export const Braces = encodeSvg(braces);

// mini SVGs (8x8) – meant for mini nodes (glyphs – 12x12)
const MINI_SVG_OPTIONS = { width: 8, height: 8 };

export const FilterMini = encodeSvg(filter, MINI_SVG_OPTIONS);
export const FilterOffMini = encodeSvg(filterOff, MINI_SVG_OPTIONS);
export const PlusMini = encodeSvg(plus, MINI_SVG_OPTIONS);
export const MinusMini = encodeSvg(minus, MINI_SVG_OPTIONS);
export const FoldersMini = encodeSvg(folders, MINI_SVG_OPTIONS);
export const FoldersOffMini = encodeSvg(foldersOff, MINI_SVG_OPTIONS);
