import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import {
  InstrumentsSearchResponse,
  InstrumentsSearchResult,
  SearchRequest,
} from 'app/modules/search/models';
import { SEARCH_QUERY_KEYS } from 'app/modules/search/queries/keys';

export const searchInstruments = async (payload: SearchRequest) => {
  return post<InstrumentsSearchResponse>('/instruments/search', payload);
};

export const useSearchInstruments = (payload?: SearchRequest) => {
  const queryClient = useQueryClient();
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const query = useQuery({
    enabled: !!payload && payload.limit > 0 && hasReadInstrumentsPermissions,
    queryFn: () => searchInstruments(payload!),
    queryKey: SEARCH_QUERY_KEYS.searchInstruments(payload),
  });

  const cachedQueryData = queryClient.getQueriesData<InstrumentsSearchResponse>(
    {
      queryKey: SEARCH_QUERY_KEYS.searchInstruments(),
    },
  );

  const instruments = useMemo(
    () =>
      cachedQueryData.reduce<InstrumentsSearchResult[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(queryData.data, acc, 'id');
        }
        return acc;
      }, []),
    [cachedQueryData],
  );

  return {
    ...query,
    instruments,
  };
};
