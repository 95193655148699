// Types
import { AlertActionTriggerModel } from 'app/modules/webhooks/models';
import { AlertDetails, AlertFiltersPayload } from 'app/modules/alerts/models';
import {
  AlertDetailsTab,
  AlertType,
  AssociatedAlert,
  AssociatedCase,
  FullAlertResponse,
} from 'app/modules/alerts/types';
import { CreateCaseLocationState } from 'app/modules/cases/models';
import {
  ArrayComparisonOperators,
  CustomDataFilters,
  SimpleComparisonOperators,
} from 'app/modules/filtersOld/models';
import { Filter } from 'app/modules/filters/models';
import { TabConfigType } from 'app/shared/CustomConfig/models';
import { U21ChipColor } from 'app/shared/u21-ui/components';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

// Constants
import {
  ALERT_FILTER_KEYS,
  NATIVE_ALERT_FILTER_KEYS_SET,
} from 'app/modules/alerts/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';

// Helpers
import { addDays, startOfDay } from 'date-fns';
import { getBEDateFormat } from 'app/shared/utils/timeHelpers';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import {
  AlertsByEntityExpensiveSorting,
  AlertsByEventExpensiveSorting,
  AlertsByObjectSorting,
  EXPENSIVE_ALERT_FILTERS,
  ExpensiveAlertFiltersType,
  GetAlertsGroupedByObjectExpensivePayload,
  GetAlertsGroupedByObjectPayload,
  RemovedExpensiveOperationsType,
} from 'app/modules/alerts/types/objectCentricInvestigation';

export const generateAssociatedAlertsText = (
  alert: AlertDetails,
  associatedAlert: AssociatedAlert,
): string => {
  const associatedAlertId = associatedAlert?.id;

  const entityIds = associatedAlert.entity_ids || [];
  const instrumentIds = associatedAlert.instrument_ids || [];

  const associatedInstruments = (alert.instruments || []).filter((instrument) =>
    instrumentIds.includes(instrument.id),
  );
  const associatedEntities = (alert.entities || []).filter((entity) =>
    entityIds.includes(entity.id),
  );

  let { title } = associatedAlert;
  // Prepare parts to append
  let instrumentText = '';
  let entityText = '';

  // If entities exist, format them
  if (associatedEntities && associatedEntities.length > 0) {
    entityText = ` associated with entity ${associatedEntities
      .map((entity) => `#${String(entity.id)} - ${entity.name_readable}`)
      .join(', ')}`;
  }

  // If instruments exist, format them
  if (associatedInstruments && associatedInstruments.length > 0) {
    instrumentText = ` associated with instrument ${associatedInstruments
      .map(
        (instrument) => `#${String(instrument.id)} - ${instrument.external_id}`,
      )
      .join(', ')}`;
  }

  // If there are associations, extend the title
  if (instrumentText || entityText) {
    title = `Alert #${associatedAlertId}${entityText}${entityText && instrumentText ? ' and' : ''}${instrumentText}`;
  }

  return title || '';
};

export const generateAlertAssociatedCaseText = (
  alert: AlertDetails,
  associatedCase: AssociatedCase,
): string => {
  const { id } = associatedCase;
  const alertCaseIds = alert.case_ids || [];

  const associatedEntities = (alert.entities || []).filter((entity) =>
    associatedCase.entity_ids.includes(entity.id),
  );

  const associatedInstruments = (alert.instruments || []).filter((instrument) =>
    associatedCase.instrument_ids.includes(instrument.id),
  );

  const associatedEvents = (alert.events || []).filter((event) =>
    associatedCase.event_ids.includes(event.id),
  );

  const associatedWithAlert = alertCaseIds.includes(id);

  let { title } = associatedCase;
  if (associatedEvents.length > 0) {
    title = `Case #${id} associated with event `;
    associatedEvents.forEach((event, idx) => {
      title += `#${String(event.id)} - ${event.external_id}`;
      // not last element, add comma
      if (idx !== associatedEvents.length - 1) {
        title += ', ';
      }
    });
  }
  // instruments description overrides events title
  if (associatedInstruments.length > 0) {
    title = `Case #${id} associated with instrument `;
    associatedInstruments.forEach((instrument, idx) => {
      title += `#${String(instrument.id)} - ${instrument.external_id}`;
      // not last element, add comma
      if (idx !== associatedInstruments.length - 1) {
        title += ', ';
      }
    });
  }
  // entities description overrides instruments title
  if (associatedEntities.length > 0) {
    title = `Case #${id} associated with entity `;
    associatedEntities.forEach((entity, idx) => {
      title += `#${String(entity.id)} - ${entity.name_readable}`;
      // not last element, add comma
      if (idx !== associatedEntities.length - 1) {
        title += ', ';
      }
    });
  }
  // alerts title overrides entities / events titles
  if (associatedWithAlert) {
    title = `Case #${id} is associated with this alert`;
  }

  return String(title);
};

export const shouldRenderActionTrigger = (
  trigger: AlertActionTriggerModel,
  alert: AlertDetails,
): boolean => {
  const closingAction = alert.closing_action_trigger_id === trigger.id;

  // check for matching alert types, if specified
  // note, this is likely a deprecated feature
  const correctAlertType =
    trigger.config.alert_type === null ||
    trigger.config.alert_type === alert.alert_type;

  if (!correctAlertType) {
    return false;
  }

  // check for the matching alert status
  // note, this may not be configurable in the ui
  const correctAlertStatus =
    trigger.config.show_on_alert_status === null ||
    trigger.config.show_on_alert_status === alert.status ||
    closingAction;

  if (!correctAlertStatus) {
    return false;
  }

  // if an action trigger and an alert belong to a queue(s)
  // check that the action trigger is associated
  // with the queue the alert is in
  const correctAlertQueue = trigger.queues?.length
    ? alert.queue_id && trigger.queues.includes(alert.queue_id)
    : true;
  if (!correctAlertQueue) {
    return false;
  }

  return true;
};

export const formatAlertTotalFlaggedString = (alert: AlertDetails) => {
  const plural = (n: number) => (n !== 1 ? 's' : '');

  const totalActions = alert.action_events?.count || 0;
  const totalTransactionEvents = alert.num_txns || 0;
  const numEntities = alert.num_entities || 0;
  const numInstruments = alert.num_instruments || 0;

  return `${totalTransactionEvents} transaction${plural(
    totalTransactionEvents,
  )}, ${totalActions} action${plural(
    totalActions,
  )}, ${numEntities} user${plural(
    numEntities,
  )}, ${numInstruments} instrument${plural(numInstruments)}`;
};

export const getAlertTypeForCustomTabs = (alertType?: AlertType) => {
  switch (alertType) {
    case AlertType.CHAINALYSIS:
      return TabConfigType.CHAINALYSIS_ALERT_TABS;
    case AlertType.WATCHLIST:
      return TabConfigType.WATCHLIST_ALERT_TABS;
    case AlertType.DARK_WEB:
      return TabConfigType.DARK_WEB_ALERT_TABS;
    case AlertType.FINCEN_314A:
      return TabConfigType.FINCEN_314A_ALERT_TABS;
    default:
      return TabConfigType.TM_ALERT_TABS;
  }
};

export const getDefaultTabObject = (
  alertTabsByType: AlertDetailsTab[],
): Record<string, AlertDetailsTab> =>
  alertTabsByType.reduce(
    (acc, tab: AlertDetailsTab) => Object.assign(acc, { [tab.path]: tab }),
    {},
  );

export const generateCreateCaseLocationState = (
  alert: AlertDetails,
  associatedAlerts: AssociatedAlert[],
  selectedAssociatedAlertIDs: number[] = [],
  assignee?: number,
): CreateCaseLocationState => {
  const associatedAlertsMap = associatedAlerts.reduce(
    (acc, i) => ({ ...acc, [i.id]: i }),
    {},
  );
  const selectedAssociatedAlerts = selectedAssociatedAlertIDs.map(
    (i) => associatedAlertsMap[i],
  );
  const alerts = [alert, ...selectedAssociatedAlerts];
  const associatedAlertEntities = selectedAssociatedAlerts.reduce(
    (acc, i) => [...acc, ...i.entity_external_ids],
    [],
  );

  const alertEntitiesFiltered =
    alert.entities?.filter(
      (entity) => alert.entity_resolutions?.[entity.id] !== 'FALSE_POSITIVE',
    ) || [];

  const entities = [
    ...new Set([
      ...alertEntitiesFiltered.map((e) => e.external_id),
      ...associatedAlertEntities,
    ]),
  ];

  // associatedAlerts do not have events
  const events = alert.events
    ?.filter((e) => alert.event_resolutions[e.id] !== 'FALSE_POSITIVE')
    .map((e) => e.external_id);
  const actionEvents =
    alert?.action_events?.events
      ?.filter((a) => alert.action_event_resolutions[a.id] !== 'FALSE_POSITIVE')
      .map((a) => a.external_id) || [];
  return {
    originating_alert_id: alert.id,
    alerts,
    assignee,
    entities,
    events,
    actionEvents,
  };
};

export const getAlertQueueAlertCounterColor = (
  value: number,
  lowThreshold: number | null,
  highThreshold: number | null,
): U21ChipColor => {
  if (!lowThreshold && !highThreshold) return 'default';
  if (highThreshold && value > highThreshold) return 'error';
  if (lowThreshold && value > lowThreshold) return 'warning';
  return 'success';
};

const filterNumbers = (array: (number | string)[]): number[] => {
  return array.filter((i): i is number => typeof i === 'number');
};

const filterStrings = (array: (number | string)[]): string[] => {
  return array.filter((i): i is string => typeof i === 'string');
};

export const createAlertListFilters = (
  filters: Filter[],
): AlertFiltersPayload => {
  const { nativeFilters, customFilters } = filters.reduce<{
    nativeFilters: Filter[];
    customFilters: Filter[];
  }>(
    (acc, i) => {
      if (NATIVE_ALERT_FILTER_KEYS_SET.has(i.key)) {
        acc.nativeFilters.push(i);
      } else {
        acc.customFilters.push(i);
      }
      return acc;
    },
    {
      nativeFilters: [],
      customFilters: [],
    },
  );

  const customDataFilters = customFilters.reduce<CustomDataFilters>(
    (acc, i) => {
      const CLASSIFIER_CUSTOM_DATA_KEY_MAPPING = {
        [Unit21DataClassifier.ENTITY]: 'entity',
        [Unit21DataClassifier.INSTRUMENT]: 'txn_instrument',
        [Unit21DataClassifier.EVENT]: 'event',
      };
      const { classifier, key } = JSON.parse(i.key);
      const customDataKey = CLASSIFIER_CUSTOM_DATA_KEY_MAPPING[classifier];
      if (!acc[customDataKey]) {
        acc[customDataKey] = [];
      }
      const { operator, value } = i;
      switch (operator) {
        case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.GT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.LT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.NOT_IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value: null,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value: null,
          });
          break;

        default:
          break;
      }

      return acc;
    },
    {},
  );

  const nativeDataFilters = nativeFilters.reduce<AlertFiltersPayload>(
    (acc, i) => {
      const { key, operator, value } = i;
      switch (key) {
        case ALERT_FILTER_KEYS.ALERT_SCORE: {
          if (operator === FILTER_OPERATOR.IS_BETWEEN_SLIDER) {
            const [min, max] = value;
            acc.min_alert_score = min;
            acc.max_alert_score = max;
          }
          break;
        }

        case ALERT_FILTER_KEYS.AMOUNT: {
          if (
            operator === FILTER_OPERATOR.IS_GREATER_THAN_NUMBER &&
            value > 0
          ) {
            acc.minimum_amount = value;
          } else if (
            operator === FILTER_OPERATOR.IS_LESS_THAN_NUMBER &&
            value > 0
          ) {
            acc.maximum_amount = value;
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_NUMBER) {
            const [min, max] = value;
            if (min > 0) {
              acc.minimum_amount = min;
            }
            if (max > 0) {
              acc.maximum_amount = max;
            }
          }
          break;
        }

        case ALERT_FILTER_KEYS.ASSIGNEE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.agent_ids = filterNumbers(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.CREATED_AT: {
          // note: this API is using the legacy date time format so using the old formatter which requires moment
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.created_start_date = getBEDateFormat(value, moment.ISO_8601);
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.created_end_date = getBEDateFormat(
              startOfDay(addDays(new Date(value), 1)),
              moment.ISO_8601,
            );
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            const [start, end] = value;
            acc.created_start_date = getBEDateFormat(start, moment.ISO_8601);
            acc.created_end_date = getBEDateFormat(
              startOfDay(addDays(new Date(end), 1)),
              moment.ISO_8601,
            );
          }
          break;
        }

        case ALERT_FILTER_KEYS.DISPOSITION: {
          if (operator === FILTER_OPERATOR.IS) {
            acc.disposition = String(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.DISPOSITIONED_AT: {
          // note: this API is using the legacy date time format so using the old formatter which requires moment
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.disposition_start_date = getBEDateFormat(
              value,
              moment.ISO_8601,
            );
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.disposition_end_date = getBEDateFormat(
              startOfDay(addDays(new Date(value), 1)),
              moment.ISO_8601,
            );
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            const [start, end] = value;
            acc.disposition_start_date = getBEDateFormat(
              start,
              moment.ISO_8601,
            );
            acc.disposition_end_date = getBEDateFormat(
              startOfDay(addDays(new Date(end), 1)),
              moment.ISO_8601,
            );
          }
          break;
        }

        case ALERT_FILTER_KEYS.ENTITY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.entity_ids = value;
          }
          break;
        }

        case ALERT_FILTER_KEYS.ID_TITLE: {
          if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
            acc.phrase = value;
          }
          break;
        }

        case ALERT_FILTER_KEYS.INSTRUMENT: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.instrument_external_ids = filterStrings(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.RULE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.rule_ids = filterNumbers(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.QUEUE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.alert_queue_ids = filterNumbers(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.SOURCE: {
          if (operator === FILTER_OPERATOR.IS) {
            acc.sources = [String(value)];
          }
          break;
        }

        case ALERT_FILTER_KEYS.STATUS: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.statuses = filterStrings(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.SUBDISPOSITION: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.subdisposition_option_ids = filterNumbers(value);
          } else if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
            acc.is_not_subdisposition_option_ids = filterNumbers(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.TAG: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.tag_ids = filterNumbers(value);
          } else if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
            acc.is_not_tag_ids = filterNumbers(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.TYPE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.alert_types = filterStrings(value);
          }
          break;
        }

        case ALERT_FILTER_KEYS.DISPOSITIONED_BY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.dispositioned_by = filterNumbers(value);
          }
          break;
        }

        default:
          break;
      }
      return acc;
    },
    {},
  );

  return {
    ...nativeDataFilters,
    custom_data_filters:
      Object.keys(customDataFilters).length > 0 ? customDataFilters : undefined,
  };
};

export const filterAlertWorkflowButtons = (
  workflowButtons: AlertActionTriggerModel[],
  alert: FullAlertResponse,
  agentTeams: number[],
  removeDisplayTagLogic?: boolean,
): AlertActionTriggerModel[] => {
  const caseTagIDSet = new Set(alert.tags.map((i) => i.id));
  const agentTeamIDSet = new Set(agentTeams);
  return workflowButtons
    .filter((i) => {
      if (i.config.close_alert && alert.status === 'CLOSED') {
        return false;
      }

      if (i.config.alert_type && i.config.alert_type !== alert.alert_type) {
        return false;
      }

      if (
        i.config.show_on_alert_status &&
        i.config.show_on_alert_status !== alert.status
      ) {
        return false;
      }

      if (
        i.queues.length &&
        alert.queue_id &&
        !i.queues.includes(alert.queue_id)
      ) {
        return false;
      }
      return true;
    })
    .filter((i) => {
      if (!i.teams.length) {
        return true;
      }
      // show if workflow has a team the agent is a part of
      return i.teams.some((j) => agentTeamIDSet.has(j));
    })
    .filter((i) => {
      if (removeDisplayTagLogic) {
        return true;
      }
      // only show if case has all the workflow button display tags
      return i.display_tags.every((j) => caseTagIDSet.has(j));
    })
    .filter((i) => {
      // don't show if case has any of the workflow button masked tags
      return !i.masking_tags.some((j) => caseTagIDSet.has(j));
    });
};

export const getChecklistDisabledReason = (
  workflowButton: AlertActionTriggerModel,
  isChecklistValidated: boolean,
): string | undefined => {
  if (!workflowButton.config.enable_if_invalid && !isChecklistValidated) {
    return 'Complete checklist first';
  }
  return undefined;
};

const INITIAL_REMOVED_EXPENSIVE_OPERATIONS: RemovedExpensiveOperationsType = {
  sortColumn: undefined,
  filters: [],
};

export function extractBasicPayloadFromExpensive(
  expensivePayload: GetAlertsGroupedByObjectExpensivePayload,
): {
  basicPayload: GetAlertsGroupedByObjectPayload;
  removedExpensiveOperations: RemovedExpensiveOperationsType;
  shouldShowDisclaimer: boolean;
} {
  // Function will receive the whole payload and remove the unsupported expensive
  // filters/aggregates so we can use the storage endpoint with the payload
  const removedExpensiveOperations = cloneDeep(
    INITIAL_REMOVED_EXPENSIVE_OPERATIONS,
  );

  // Remove expensive sorting if it's not supported by the storage endpoint
  let basicSortBy = AlertsByObjectSorting.OPEN_ALERTS;
  if (
    Object.keys(AlertsByObjectSorting).includes(expensivePayload.sort_column)
  ) {
    // Expensive payload is sorting by a basic sorting option, no need to change it
    basicSortBy = expensivePayload.sort_column as AlertsByObjectSorting;
  } else {
    // If we are here it means that the sort column can not be of type AlertsByObjectSorting, casting since TS can't infer this
    removedExpensiveOperations.sortColumn = expensivePayload.sort_column as
      | AlertsByEntityExpensiveSorting
      | AlertsByEventExpensiveSorting;
  }

  // Remove expensive filters if they're not supported by the storage endpoint
  const filteredPayload = Object.keys(expensivePayload).reduce(
    (acc, key: ExpensiveAlertFiltersType) => {
      if (EXPENSIVE_ALERT_FILTERS.includes(key)) {
        if (expensivePayload[key] !== undefined && key !== 'sort_column') {
          // Sort column is tracked separately
          removedExpensiveOperations.filters.push(key);
        }
        return acc;
      }
      return {
        ...acc,
        [key]: expensivePayload[key],
      };
    },
    {} as Omit<
      GetAlertsGroupedByObjectExpensivePayload,
      ExpensiveAlertFiltersType
    >,
  );

  // Return the basic payload and the removed expensive operations
  return {
    basicPayload: {
      ...filteredPayload,
      sort_column: basicSortBy,
    },
    removedExpensiveOperations,
    shouldShowDisclaimer: !isEqual(
      removedExpensiveOperations,
      INITIAL_REMOVED_EXPENSIVE_OPERATIONS,
    ),
  };
}
