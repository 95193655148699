import { KeyboardEvent } from 'react';

import { FeatureFlag } from 'app/shared/featureFlags/models';
import {
  Filter,
  FilterOption,
  IsNotOneOfOperatorAndValue,
  IsOneOfOperatorAndValue,
  IsOperatorAndValue,
} from 'app/modules/filters/models';

import { AlertSource, AlertType } from 'app/modules/alerts/types';
import { ALERT_TYPE_LABELS } from 'app/modules/alerts/constants';
import {
  TAG_FILTER_OPTION,
  getAgentFilterOption,
  getEntityFilterOption,
  getInstrumentFilterOption,
  getQueueFilterOption,
} from 'app/modules/filters/filters';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { InvestigationStatus } from 'app/modules/investigations/models';
import { QueueType } from 'app/modules/queues/models';
import PERMISSIONS from 'app/shared/utils/permissions';

import { DispositionFilterDisplay } from 'app/modules/filters/components/DispositionFilterDisplay';
import { RuleFilterDisplay } from 'app/modules/filters/components/RuleFilterDisplay';
import { SubdispositionFilterDisplay } from 'app/modules/filters/components/SubdispositionFilterDisplay';
import {
  U21DispositionSelect,
  U21RuleSelect,
  U21SubdispositionAndOptionsSelect,
} from 'app/shared/u21-ui/components/dashboard';

export const ALERT_FILTER_KEYS = {
  ALERT_SCORE: 'alert_score',
  AMOUNT: 'amount',
  ASSIGNEE: 'agent_ids',
  CREATED_AT: 'created_at',
  DISPOSITION: 'disposition',
  DISPOSITIONED_AT: 'dispositioned_at',
  ENTITY: 'entity',
  ID_TITLE: 'phrase',
  INSTRUMENT: 'instrument',
  QUEUE: 'queue',
  RULE: 'rule',
  SOURCE: 'source',
  STATUS: 'status',
  SUBDISPOSITION: 'subdisposition',
  TAG: 'tag',
  TYPE: 'type',
  DISPOSITIONED_BY: 'dispositioned_by_ids',
} as const;

export const NATIVE_ALERT_FILTER_KEYS_SET = new Set<string>(
  Object.values(ALERT_FILTER_KEYS),
);

const nonNegativeNumberOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === '-') {
    e.preventDefault();
  }
};

export const ALL_ALERT_FILTER_OPTIONS: FilterOption[] = [
  {
    featureFlag: FeatureFlag.ALERT_SCORE,
    key: ALERT_FILTER_KEYS.ALERT_SCORE,
    label: 'Alert score',
    type: FILTER_TYPE.SLIDER_RANGE,
    customize: {
      [FILTER_OPERATOR.IS_BETWEEN_SLIDER]: {
        defaultValue: [0, 100],
        inputProps: { min: 0 },
      },
    },
  },
  {
    key: ALERT_FILTER_KEYS.AMOUNT,
    label: 'Amount',
    type: FILTER_TYPE.NUMBER_RANGE,
    customize: {
      [FILTER_OPERATOR.IS_GREATER_THAN_NUMBER]: {
        inputProps: {
          onKeyPress: nonNegativeNumberOnKeyPress,
        },
      },
      [FILTER_OPERATOR.IS_LESS_THAN_NUMBER]: {
        inputProps: {
          onKeyPress: nonNegativeNumberOnKeyPress,
        },
      },
      [FILTER_OPERATOR.IS_BETWEEN_NUMBER]: {
        inputProps: {
          minInputProps: { onKeyPress: nonNegativeNumberOnKeyPress },
          maxInputProps: { onKeyPress: nonNegativeNumberOnKeyPress },
        },
      },
    },
  },
  {
    key: ALERT_FILTER_KEYS.ASSIGNEE,
    ...getAgentFilterOption('Assignee', true),
  },
  {
    key: ALERT_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: ALERT_FILTER_KEYS.DISPOSITIONED_BY,
    ...getAgentFilterOption('Dispositioned by', true),
  },
  {
    key: ALERT_FILTER_KEYS.DISPOSITION,
    label: 'Disposition',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        Display: ({ value }: Omit<IsOperatorAndValue<string>, 'onChange'>) => {
          return <DispositionFilterDisplay value={value} />;
        },
        Input: ({ onChange, value }: IsOperatorAndValue<string>) => {
          return (
            <U21DispositionSelect
              autoFocus
              backdrop
              clearable={false}
              onChange={onChange}
              // alert filters searches disposition via text but this is no longer
              // necessary since we have formalized dispositions. Apply a hack
              // so that users can select dispositions but internally it's just
              // searching dispositions via text
              useTitle
              value={value}
            />
          );
        },
      },
    },
  },
  {
    key: ALERT_FILTER_KEYS.DISPOSITIONED_AT,
    label: 'Dispositioned at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: ALERT_FILTER_KEYS.ENTITY,
    ...getEntityFilterOption(),
  },
  {
    key: ALERT_FILTER_KEYS.ID_TITLE,
    label: 'ID / Title',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: ALERT_FILTER_KEYS.INSTRUMENT,
    ...getInstrumentFilterOption(),
  },
  {
    key: ALERT_FILTER_KEYS.QUEUE,
    ...getQueueFilterOption(QueueType.ALERT_QUEUE),
  },
  {
    key: ALERT_FILTER_KEYS.RULE,
    label: 'Rule',
    permissions: [PERMISSIONS.readRules],
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Display: ({
          value,
        }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
          return <RuleFilterDisplay ids={value} />;
        },
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
          return (
            <U21RuleSelect
              autoFocus
              backdrop
              clearable={false}
              onChange={onChange}
              label={undefined}
              multi
              value={value}
            />
          );
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
  {
    key: ALERT_FILTER_KEYS.SOURCE,
    label: 'Source',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        inputProps: {
          options: [
            { text: 'Internal', value: AlertSource.INTERNAL },
            { text: 'External', value: AlertSource.EXTERNAL },
          ],
        },
      },
    },
  },
  {
    key: ALERT_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: {
          options: [
            { text: 'Open', value: InvestigationStatus.OPEN },
            { text: 'Closed', value: InvestigationStatus.CLOSED },
            { text: 'In Review', value: InvestigationStatus.IN_REVIEW },
          ],
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
  {
    key: ALERT_FILTER_KEYS.SUBDISPOSITION,
    label: 'Subdisposition',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Display: ({
          operator,
          value,
        }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
          return (
            <SubdispositionFilterDisplay ids={value} operator={operator} />
          );
        },
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
          return (
            <U21SubdispositionAndOptionsSelect
              autoFocus
              backdrop
              clearable={false}
              multi
              onChange={onChange}
              value={value}
            />
          );
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
        Display: ({
          operator,
          value,
        }: Omit<IsNotOneOfOperatorAndValue<number>, 'onChange'>) => {
          return (
            <SubdispositionFilterDisplay ids={value} operator={operator} />
          );
        },
        Input: ({ onChange, value }: IsNotOneOfOperatorAndValue<number>) => {
          return (
            <U21SubdispositionAndOptionsSelect
              autoFocus
              backdrop
              clearable={false}
              multi
              onChange={onChange}
              value={value}
            />
          );
        },
      },
    },
  },
  {
    key: ALERT_FILTER_KEYS.TAG,
    ...TAG_FILTER_OPTION,
  },
  {
    key: ALERT_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: {
          options: Object.values(AlertType).map((i) => ({
            text: ALERT_TYPE_LABELS[i],
            value: i,
          })),
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
];

export const MY_ALERT_FILTER_OPTIONS = ALL_ALERT_FILTER_OPTIONS.filter(
  (i) => i.key !== ALERT_FILTER_KEYS.ASSIGNEE,
);

export const ALERT_QUEUE_ALERT_FILTER_OPTIONS = ALL_ALERT_FILTER_OPTIONS.filter(
  (i) => i.key !== ALERT_FILTER_KEYS.QUEUE,
);

export const MY_ALERT_DEFAULT_FILTERS: Filter[] = [
  {
    key: ALERT_FILTER_KEYS.STATUS,
    operator: FILTER_OPERATOR.IS_ONE_OF,
    value: [InvestigationStatus.OPEN],
  },
];
