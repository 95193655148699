import { get, post, put } from 'app/shared/utils/fetchr';

// Models
import {
  EditEntityPayload,
  EntityAlertsPayload,
  GlobalWhitelistEntitiesPayload,
} from 'app/modules/entities/models';
import { FullEntityResponse } from 'app/modules/entities/types';
import { PaginationPayload } from 'app/shared/models';

// Utils
import routes from 'app/shared/utils/routes';

export const getEntity = (id) => get(`/entities/${encodeURIComponent(id)}`);

export const editEntity = (payload: EditEntityPayload) => {
  const { id, ...details } = payload;
  const path = routes.patronus.entitiesEditId.replace(':id', `${id}`);
  return put(path, details);
};

export const getEntityAlerts = (payload: EntityAlertsPayload) => {
  const { entityExternalId, ...details } = payload;
  const path = routes.patronus.entitiesIdPaginatedAlerts.replace(
    ':entity-external-id',
    `${encodeURIComponent(entityExternalId)}`,
  );
  return post(path, details);
};

export const addEntitiesToGlobalWhitelist = (
  payload: GlobalWhitelistEntitiesPayload,
) => {
  return post(routes.patronus.entitiesWhitelistGlobally, payload);
};

export const removeEntitiesFromGlobalWhitelist = (
  payload: GlobalWhitelistEntitiesPayload,
) => {
  return post(routes.patronus.entitiesUnwhitelistGlobally, payload);
};

export const retrieveEntitiesFromGlobalWhitelist = (
  payload: PaginationPayload,
) => {
  return post(
    routes.patronus.entitiesPaginatedGloballyWhitelistedRules,
    payload,
  );
};

export const getEntityByExternalId = (
  entityExternalId: string,
): Promise<FullEntityResponse> =>
  get(`/unit21/entities/${encodeURIComponent(entityExternalId)}`);
