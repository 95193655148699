import { KeyboardEvent } from 'react';

import {
  FilterOption,
  IsOneOfOperatorAndValue,
} from 'app/modules/filters/models';

import {
  DATA_LABEL_FILTER_OPTION,
  getEntityFilterOption,
} from 'app/modules/filters/filters';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';

import { TxnTypeSelect } from 'app/modules/transactions/components/TxnTypeSelect';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { StatusSelect } from 'app/modules/transactions/components/StatusSelect';

export const TRANSACTION_FILTER_KEYS = {
  AMOUNT: 'amount',
  CURRENCY: 'currency',
  ENTITY: 'entity',
  RECEIVER_ENTITY: 'receiver_entity',
  SENDER_ENTITY: 'sender_entity',
  STATUS: 'status',
  TAG: 'tag',
  TIMESTAMP: 'event_time',
  TYPE: 'type',
} as const;

export const TRANSACTION_EVENT_TIME_OPTION: FilterOption = {
  key: TRANSACTION_FILTER_KEYS.TIMESTAMP,
  label: 'Event time',
  type: FILTER_TYPE.DATE_RANGE,
  unit21DataClassifier: Unit21DataClassifier.EVENT,
};

export const TRANSACTION_STATUS_OPTION: FilterOption = {
  key: TRANSACTION_FILTER_KEYS.STATUS,
  label: 'Status',
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      Input: ({ onChange, value }: IsOneOfOperatorAndValue<string>) => {
        return (
          <StatusSelect
            autoFocus
            backdrop
            clearable={false}
            onChange={onChange}
            value={value}
          />
        );
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
  },
  unit21DataClassifier: Unit21DataClassifier.EVENT,
};

const nonNegativeNumberOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === '-') {
    e.preventDefault();
  }
};

export const ALL_TRANSACTION_FILTER_OPTIONS: FilterOption[] = [
  {
    key: TRANSACTION_FILTER_KEYS.AMOUNT,
    label: 'Amount',
    type: FILTER_TYPE.NUMBER_RANGE,
    unit21DataClassifier: Unit21DataClassifier.EVENT,
    customize: {
      [FILTER_OPERATOR.IS_GREATER_THAN_NUMBER]: {
        inputProps: {
          onKeyPress: nonNegativeNumberOnKeyPress,
        },
      },
      [FILTER_OPERATOR.IS_LESS_THAN_NUMBER]: {
        inputProps: {
          onKeyPress: nonNegativeNumberOnKeyPress,
        },
      },
      [FILTER_OPERATOR.IS_BETWEEN_NUMBER]: {
        inputProps: {
          minInputProps: { onKeyPress: nonNegativeNumberOnKeyPress },
          maxInputProps: { onKeyPress: nonNegativeNumberOnKeyPress },
        },
      },
    },
  },
  {
    key: TRANSACTION_FILTER_KEYS.CURRENCY,
    label: 'Currency',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TRANSACTION_FILTER_KEYS.ENTITY,
    ...getEntityFilterOption(),
  },
  TRANSACTION_EVENT_TIME_OPTION,
  {
    key: TRANSACTION_FILTER_KEYS.RECEIVER_ENTITY,
    ...getEntityFilterOption({ label: 'Receiver entity', useExternalID: true }),
  },
  {
    key: TRANSACTION_FILTER_KEYS.SENDER_ENTITY,
    ...getEntityFilterOption({ label: 'Sender entity', useExternalID: true }),
  },
  TRANSACTION_STATUS_OPTION,
  {
    key: TRANSACTION_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<string>) => {
          return (
            <TxnTypeSelect
              autoFocus
              backdrop
              clearable={false}
              onChange={onChange}
              value={value}
            />
          );
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
    unit21DataClassifier: Unit21DataClassifier.EVENT,
  },
  {
    key: TRANSACTION_FILTER_KEYS.TAG,
    ...DATA_LABEL_FILTER_OPTION,
  },
];
