// Components
import { EventChip } from 'app/modules/alerts/components/AlertDetails/EventChip';

// Models
import {
  U21TableColumnTypes,
  U21TableColumnTypesType,
} from 'app/shared/u21-ui/components';
import {
  U21ActionEventChip,
  U21EntityChip,
  U21InstrumentChip,
} from 'app/shared/u21-ui/components/dashboard';
import { U21DataChipType } from 'app/shared/u21-ui/components/dashboard/dataChip/U21DataChip';

const LIST: U21TableColumnTypesType = 'list';

export const ENTITY_IDS_COLUMN = {
  id: 'entity_ids',
  accessor: 'entity_ids',
  Header: 'Entities',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    getShowLessText: () => 'Show fewer entities',
    getShowMoreText: (length: number) => `Show all ${length} entities`,
    displayFunc: (entityID: number) => (
      <U21EntityChip
        key={entityID}
        id={entityID}
        name={`Entity #${entityID}`}
      />
    ),
  },
};

export const EVENT_IDS_COLUMN = {
  id: 'event_ids',
  accessor: 'event_ids',
  Header: 'Transactions',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    displayFunc: (eventID: number) => (
      <EventChip key={eventID} eventID={eventID} />
    ),
  },
};

export const ACTION_EVENT_IDS_COLUMN = {
  id: 'action_event_ids',
  accessor: 'action_event_ids',
  Header: 'Action Events',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    displayFunc: (eventID: number) => (
      <U21ActionEventChip
        key={eventID}
        id={eventID}
        label={`Action Event #${eventID}`}
        type={U21DataChipType.ACTION_EVENT}
      />
    ),
  },
};

export const INSTRUMENT_IDS_COLUMN = {
  id: 'instrument_ids',
  accessor: 'instrument_ids',
  Header: 'Instruments',
  disableSortBy: true,
  type: LIST,
  cellProps: {
    getShowLessText: () => 'Show fewer instruments',
    getShowMoreText: (length: number) => `Show all ${length} instruments`,
    displayFunc: (instrumentID: number) => (
      <U21InstrumentChip
        key={instrumentID}
        id={instrumentID}
        label={`Instrument #${instrumentID}`}
      />
    ),
  },
};

export const CREATED_AT_COLUMN = {
  id: 'created_at',
  accessor: 'created_at',
  Header: 'Created at',
  type: U21TableColumnTypes.DATETIME,
};
