import { snakeCase } from 'lodash';
import {
  InvestigationChecklist,
  InvestigationChecklistTypes,
} from 'app/modules/investigationChecklist/models';
import { CustomChecklistDefinition } from 'app/shared/customChecklist/models';
import { AlertFiltersPayload } from 'app/modules/alerts/models';
import { CaseFiltersPayload } from 'app/modules/cases/models';
import { Filter } from 'app/modules/filters/models';
import { createAlertListFilters } from 'app/modules/alerts/helpers';
import { createCaseFilters } from 'app/modules/cases/utils';
import { consoleWarn } from 'app/shared/utils/console';

// Constants
import { CHECKLIST_ITEMS_OPTIONS } from 'app/shared/u21-ui/components/dashboard/U21CreateChecklistItemModal';

// Returns true/false whether an Investigation Checklist is valid or not
// Valid Checklist Criteria
// - Checklist has a name
// - Checklist has a description
// - Checklist has at least one group (validDefinition)
// - All checklist groups have atleast one item (validDefinition)
export const validateInvestigationChecklist = (
  checklist: InvestigationChecklist,
): boolean => {
  const validName = checklist?.name && checklist.name.length > 0;

  const validDescription =
    checklist?.description && checklist.description.length > 0;

  const validDefinition =
    checklist?.definition &&
    checklist.definition.length > 0 &&
    checklist.definition.filter(
      (group) => !group.items || group.items.length === 0,
    ).length === 0;

  return Boolean(validName && validDescription && validDefinition);
};

// Given a 'base key name' and a list of existing groups or items
// This function is designed to give you a new key that is guaranteed to be unique
export const createUniqueItemKey = (
  keyBase: string,
  existingItems: CustomChecklistDefinition[],
): CustomChecklistDefinition['key'] => {
  // Grab the number value on the very last group item
  const lastKey = existingItems.at(-1)?.key || '';
  const lastNumber = Number(lastKey.split('-').at(-1)) || 0;
  return `${snakeCase(keyBase) || 'key'}-${lastNumber + 1}`;
};

export const getChecklistItemDefinition = (checklistItemType: string) => {
  return CHECKLIST_ITEMS_OPTIONS.find(
    (itemDef) => itemDef.value === checklistItemType,
  );
};

export const convertFilters = (
  filters: Filter[],
  type: InvestigationChecklistTypes,
): AlertFiltersPayload | CaseFiltersPayload | undefined => {
  switch (type) {
    case InvestigationChecklistTypes.ALERT_CHECKLIST:
      return createAlertListFilters(filters);
    case InvestigationChecklistTypes.CASE_CHECKLIST:
      return createCaseFilters(filters);
    default:
      consoleWarn(`Not implemented for: ${type}`);
      return undefined;
  }
};
