import {
  HTMLProps,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FieldSubscription } from 'final-form';
import { FormFieldError } from 'app/shared/models/form';
import { U21HelpTooltipProps } from 'app/shared/u21-ui/components/display/U21HelpTooltip';

import { getDOMProps } from 'app/shared/utils/react';
import { isEqual as lodashIsEqual } from 'lodash';
import styled from 'styled-components';
import useId from '@mui/utils/useId';

import {
  Field as ReactFormField,
  FieldRenderProps,
  UseFieldConfig,
  useForm,
} from 'react-final-form';
import { FormControl } from '@mui/material';
import { U21FormContext } from 'app/shared/u21-ui/components/form/U21FormContext';
import { U21FormError } from 'app/shared/u21-ui/components/form/U21FormError';
import { U21FormLabel } from 'app/shared/u21-ui/components/form/U21FormLabel';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import {
  U21EncryptedTextField,
  U21EncryptedTextFieldProps,
} from 'app/shared/u21-ui/components/input/text-field/U21EncryptedTextField';

export enum U21EncryptedFormFieldTypes {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
}

export interface U21EncryptedFormFieldProps
  extends Omit<HTMLProps<HTMLDivElement>, 'label' | 'type'> {
  allowNull?: boolean;
  description?: string;
  disabled?: boolean;
  fieldProps: Omit<U21EncryptedTextFieldProps, 'onChange'> & {
    onChange?: U21EncryptedTextFieldProps['onChange'];
  };
  help?: U21HelpTooltipProps['help'];
  hidden?: boolean;
  isEqual?: UseFieldConfig<string>['isEqual'];
  label?: ReactNode;
  name: string;
  required?: boolean;
  subscription?: FieldSubscription;
  suppressError?: boolean;
  type: `${U21EncryptedFormFieldTypes}`;
}

const FORM_TEXT_FIELD_TYPE_MAPPING: Partial<
  Record<U21EncryptedFormFieldTypes, U21EncryptedTextFieldProps['type']>
> = {
  [U21EncryptedFormFieldTypes.TEXT]: 'text',
  [U21EncryptedFormFieldTypes.TEXTAREA]: 'textarea',
};

// function to treat undefined and empty string as the same for U21Form so that
// we don't show the leave warning since effectively the input hasn't changed
const isEqualText = (a, b) => {
  if ((a === '' && b === undefined) || (a === undefined && b === '')) {
    return true;
  }
  return lodashIsEqual(a, b);
};

const IS_EQUAL_BY_TYPE: Partial<
  Record<U21EncryptedFormFieldTypes, UseFieldConfig<void>['isEqual']>
> = {
  [U21EncryptedFormFieldTypes.TEXT]: isEqualText,
  [U21EncryptedFormFieldTypes.TEXTAREA]: isEqualText,
};

export const U21EncryptedFormField = ({
  description,
  disabled: disabledProp,
  fieldProps,
  help,
  hidden,
  label,
  name,
  required,
  subscription,
  suppressError = false,
  type,
  isEqual = IS_EQUAL_BY_TYPE[type] || lodashIsEqual,
  allowNull,
  ...rest
}: U21EncryptedFormFieldProps) => {
  const { disabled: disabledContext, loading } = useContext(U21FormContext);
  const id = useId(fieldProps?.id);

  const [internalFormFieldError, setInternalFormFieldError] = useState<
    FormFieldError | undefined
  >(undefined);

  const form = useForm();

  useEffect(() => {
    // setFieldError ideally should set error messages within form state,
    // but it is not respected because the form + form field validate
    // functions will run and overwrite it. To get it into form state,
    // we save the error message to local state and return it in validate.
    // The setFieldError merely serves as a way to retrigger validation.
    // this also must be done after internalFormFieldError is updated so the
    // validation function has access to the latest internalFormFieldError
    form.mutators.setFieldError(name, internalFormFieldError);
  }, [form, internalFormFieldError, name]);

  const onLoadingChange = useCallback(
    (isLoading: boolean) => {
      form.mutators.setFormLoading(isLoading);
    },
    [form.mutators],
  );

  return (
    <ReactFormField
      isEqual={isEqual}
      name={name}
      subscription={{
        error: true,
        invalid: true,
        touched: true,
        value: true,
        ...subscription,
      }}
      validate={() => internalFormFieldError}
      allowNull={allowNull}
    >
      {({ input, meta }: FieldRenderProps<string>) => {
        if (hidden) {
          return null;
        }
        const error = meta.touched && meta.invalid;
        const disabled = disabledContext || disabledProp || loading;

        return (
          <div {...getDOMProps(rest)}>
            <FormControl error={error} fullWidth>
              {Boolean(label) && (
                <U21FormLabel
                  disabled={disabled}
                  id={`${id}-label`}
                  htmlFor={id}
                  error={error}
                  help={help}
                  required={required}
                >
                  {label}
                </U21FormLabel>
              )}
              {(() => {
                const formFieldProps = {
                  disabled,
                  error,
                  required,
                  ...fieldProps,
                  ...input,
                };
                switch (type) {
                  case U21EncryptedFormFieldTypes.TEXT:
                  case U21EncryptedFormFieldTypes.TEXTAREA:
                    return (
                      <U21EncryptedTextField
                        id={id}
                        onError={setInternalFormFieldError}
                        {...formFieldProps}
                        onChange={(value) => {
                          input?.onChange(value);
                          fieldProps.onChange?.(value);
                        }}
                        onLoadingChange={onLoadingChange}
                        value={input.value === '' ? undefined : input.value}
                        type={FORM_TEXT_FIELD_TYPE_MAPPING[type]}
                      />
                    );

                  default:
                    return null;
                }
              })()}
              {!suppressError && <U21FormError error={error && meta.error} />}
              <StyledU21Typography color="text.secondary" variant="body2">
                {description}
              </StyledU21Typography>
            </FormControl>
          </div>
        );
      }}
    </ReactFormField>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-top: 4px;
`;
