// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import {
  EntitiesActionTypes,
  EditEntityPayload,
  EditEntityResult,
  EntityAlertsPayload,
} from 'app/modules/entities/models';

export const editEntity = (payload: EditEntityPayload) => {
  return createAction(EntitiesActionTypes.EDIT_ENTITY, payload);
};

export const editEntitySuccess = (payload: EditEntityResult) => {
  return createAction(EntitiesActionTypes.EDIT_ENTITY_SUCCESS, payload);
};

export const retrieveEntity = (
  entityId: string | number,
  ignoreComments: boolean = false,
) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY, {
    entityId,
    ignoreComments,
  });
};

export const retrieveEntitySuccess = (payload) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY_SUCCESS, payload);
};

export const retrieveEntityAlerts = (payload: EntityAlertsPayload) => {
  return createAction(EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS, payload);
};

export const retrieveEntityAlertsSuccess = (payload: any) => {
  return createAction(
    EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS_SUCCESS,
    payload,
  );
};

export const clearAllEntityState = () => {
  return createAction(EntitiesActionTypes.CLEAR_ALL_ENTITY_STATE);
};

const EntitiesActionsList = {
  editEntitySuccess,
  retrieveEntitySuccess,
  retrieveEntityAlertsSuccess,
  clearAllEntityState,
};

export type EntitiesActions = ActionsUnion<typeof EntitiesActionsList>;
