import { FullTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectDLEnabledForInstrumentDetail } from 'app/shared/featureFlags/selectors';

export const useInstrumentDetails = <TSelect = FullTxnInstrumentResponse>(
  instrumentId: string,
  select?: (data: FullTxnInstrumentResponse) => TSelect,
  enabled = true,
) => {
  const useNewEndpoint = useSelector(selectDLEnabledForInstrumentDetail);

  const endpoint = useNewEndpoint
    ? `/instruments/cached/${instrumentId}`
    : `/instruments/${instrumentId}`;

  return useQuery({
    queryFn: () => get<FullTxnInstrumentResponse>(endpoint),
    queryKey: [
      ...INSTRUMENT_QUERY_KEYS.getInstrumentDetails(instrumentId),
      endpoint,
    ],
    select,
    enabled: Boolean(instrumentId) && enabled,
  });
};
