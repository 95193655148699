/*

The fields are taken from Alert.content, a JSONB column and, while
some can be accounted for in ChainalysisIngestor (1), some only
exist by reference in the old table

1) u21/umbridge/ingest/chainalysis.py::ChainalysisIngestor

*/
export enum ChainalysisContentField {
  ALERT_IDENTIFIER = 'alertIdentifier',
  ASSET = 'asset',
  LEVEL = 'level',
  USER_ID = 'userId',
  SERVICE = 'service',
  CATEGORY = 'category',
  CREATED_AT = 'createdAt',
  DIRECTION = 'direction',
  TIMESTAMP = 'timestamp',
  ALERT_STATUS = 'alertStatus',
  EXPOSURE_TYPE = 'exposureType',
  MIN_THRESHOLD = 'minThreshold',
  MAX_THRESHOLD = 'maxThreshold',
  TRANSACTION_HASH = 'transactionHash',
  TRANSACTION_INDEX = 'transactionIndex',
  TRANSFER_REFERENCE = 'transferReference',
  TRANSFER_REPORTED_AT = 'transferReportedAt',
  TRANSFER_OUTPUT_ADDRESS = 'transferOutputAddress',
  KYT_ALERT_URL = 'kytAlertUrl',
  ALERT_AMOUNT_USD = 'alertAmountUsd',
  ALERT_STATUS_CREATED_AT = 'alertStatusCreatedAt',
  ALERT_STATUS_CREATED_BY = 'alertStatusCreatedBy',
  RULE = 'rule',
  TRANSFERRED_VALUE_PERCENTAGE = 'transferredValuePercentage',
}

export type ChainalysisAlertContent = {
  [key in ChainalysisContentField]?: string;
};
