import {
  forwardRef,
  ForwardedRef,
  HTMLProps,
  ReactNode,
  SyntheticEvent,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { FormControlLabel, Radio, RadioProps } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Tooltip } from 'app/shared/u21-ui/components';

export type U21RadioValue = string | number;

export interface U21RadioProps<TValue extends U21RadioValue>
  extends Omit<HTMLProps<HTMLButtonElement>, 'label' | 'onChange'> {
  checked?: boolean;
  description?: string;
  disabled?: boolean;
  ellipsis?: boolean;
  inputProps?: RadioProps['inputProps'];
  label?: ReactNode;
  onChange: (value: TValue, e: SyntheticEvent) => void;
  tooltip?: string;
  value: TValue;
}

export const U21RadioInner = <TValue extends U21RadioValue>(
  props: U21RadioProps<TValue>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const {
    checked,
    description,
    disabled,
    ellipsis,
    inputProps = {},
    label,
    onChange,
    tooltip,
    value,
    ...rest
  } = props;

  const radioButton = (
    <Radio
      value={value}
      checked={checked}
      disabled={disabled}
      // e.target.value is based on the value prop which is typed as TValue
      onChange={(e) => onChange(e.target.value as TValue, e)}
      {...(label
        ? {}
        : {
            // HTMLButtonElement extends from HTMLElement so should be safe
            ref: ref as ForwardedRef<HTMLButtonElement>,
            ...getDOMProps(rest),
          })}
      inputProps={inputProps}
    />
  );

  if (!label) {
    return <U21Tooltip tooltip={tooltip}>{radioButton}</U21Tooltip>;
  }

  return (
    <U21Tooltip tooltip={tooltip}>
      <StyledFormControlLabel
        ref={ref}
        control={radioButton}
        disableTypography
        label={
          <LabelContainer>
            {typeof label === 'string' ? (
              <U21Typography
                color={disabled ? 'text.disabled' : 'text.primary'}
                ellipsis={ellipsis}
                variant="body2"
              >
                {label}
              </U21Typography>
            ) : (
              label
            )}
            <U21Typography
              color="text.disabled"
              ellipsis={ellipsis}
              variant="body2"
            >
              {description}
            </U21Typography>
          </LabelContainer>
        }
        {...getDOMProps(rest)}
      />
    </U21Tooltip>
  );
};

export const U21Radio = forwardRef(U21RadioInner);

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 32px;
  width: fit-content;
  // account for -11px margin-left default mui style
  max-width: calc(100% + 11px);

  .MuiFormControlLabel-label {
    margin: 4px 0;
  }
`;

const LabelContainer = styled.div`
  overflow: hidden;
  margin: 4px 0;
  ${(props) => props.theme.typography.body2};
`;
