import { OFFSET_DEFAULT } from 'app/shared/constants';

import { entitiesSearch } from 'app/modules/search/actions';
import { formatEntityOptions, ValueField } from 'app/modules/search/helpers';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';

interface Props {
  ids: string[];
  label: string;
  valueField?: ValueField;
}

export const EntityFilterDisplay = (props: Props) => {
  const { ids, label, valueField = ValueField.ID } = props;
  const dispatch = useDispatch();
  // using search/selectors caused an import cycle
  const entities = useSelector(
    (state: RootState) => state.search.entities.data,
  );
  const hasReadEntitiesPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );

  // Map of external IDs to options
  const entityOptionsMap = useMemo(
    () =>
      formatEntityOptions(entities, 'external_id', valueField).reduce(
        (acc, i) => ({
          ...acc,
          [i.value]: i,
        }),
        {},
      ),
    [entities, valueField],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);
  useEffect(() => {
    if (hasReadEntitiesPermissions) {
      const missingValues = ids.filter((i) => !entityOptionsMap[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        if (valueField === ValueField.ID) {
          dispatch(
            entitiesSearch({
              ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'unit21_id',
            }),
          );
        } else {
          dispatch(
            entitiesSearch({
              external_ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'external_id',
            }),
          );
        }
      }
    }
  }, [dispatch, hasReadEntitiesPermissions, ids, entityOptionsMap, valueField]);

  const values = useMemo(
    () => ids.map((i) => entityOptionsMap[i]?.text || i),
    [entityOptionsMap, ids],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
