import { FilterOption } from 'app/modules/filters/models';

import { DATA_LABEL_FILTER_OPTION } from 'app/modules/filters/filters';
import { FILTER_TYPE } from 'app/modules/filters/constants';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

export const INSTRUMENT_FILTER_KEYS = {
  REGISTERED_AT: 'registered_at',
  STATUS: 'status',
  TAG: 'tag',
  TYPE: 'instrument_type',
  SUBTYPE: 'instrument_subtype',
} as const;

export const ALL_INSTRUMENT_FILTER_OPTIONS: FilterOption[] = [
  {
    key: INSTRUMENT_FILTER_KEYS.REGISTERED_AT,
    label: 'Registered at',
    type: FILTER_TYPE.DATE_RANGE,
    unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
  },
  {
    key: INSTRUMENT_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.TEXT,
    unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
  },
  {
    key: INSTRUMENT_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.TEXT,
    unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
  },
  {
    key: INSTRUMENT_FILTER_KEYS.SUBTYPE,
    label: 'Subtype',
    type: FILTER_TYPE.TEXT,
    unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
  },
  {
    key: INSTRUMENT_FILTER_KEYS.TAG,
    ...DATA_LABEL_FILTER_OPTION,
  },
];
