import { COLOR_STYLE } from 'app/shared/u21-ui/components/display/typography/styles';

import { forwardRef, HTMLProps, ReactElement, ReactNode } from 'react';
import { getDOMProps, isRenderable } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import { Typography, TypographyProps } from '@mui/material';
import {
  U21Spacer,
  U21SpacerProps,
} from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21TypographyIcon } from 'app/shared/u21-ui/components/display/typography/U21TypographyIcon';
import {
  U21Tooltip,
  U21TooltipProps,
} from 'app/shared/u21-ui/components/display/U21Tooltip';
import { U21TypographyColor } from 'app/shared/u21-ui/components/display/typography/models';

export interface U21TypographyProps
  extends Omit<HTMLProps<HTMLDivElement>, 'ref'> {
  align?: U21SpacerProps['align'];
  children?: ReactNode;
  color?: U21TypographyColor;
  component?: TypographyProps['component'];
  ellipsis?: boolean;
  icon?: ReactElement;
  iconColor?: U21TypographyColor;
  inline?: boolean;
  textAlign?: TypographyProps['align'];
  tooltip?: ReactNode;
  tooltipProps?: Omit<U21TooltipProps, 'children' | 'tooltip'>;
  variant?: TypographyProps['variant'];
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
}

export const U21Typography = forwardRef<HTMLDivElement, U21TypographyProps>(
  (props, ref) => {
    const {
      align,
      children,
      color = 'text.primary',
      component,
      htmlFor,
      icon,
      iconColor,
      inline,
      variant = 'body1',
      ellipsis,
      textAlign,
      textTransform,
      tooltip,
      tooltipProps,
      ...rest
    } = props;

    // Show full text in tooltip if ellipsis cut off text
    const { ref: internalRef } = useResizeDetector<HTMLSpanElement>({
      handleHeight: false,
      refreshMode: 'debounce',
      refreshRate: 300,
    });

    const hasFullTextTooltip =
      (internalRef?.current?.scrollWidth || 0) >
      (internalRef?.current?.clientWidth || 0);

    if (!isRenderable(children)) {
      return null;
    }

    const displayTooltip = (() => {
      if (tooltip && hasFullTextTooltip) {
        return (
          <U21Spacer dividers spacing={0.5} useFlexGap>
            {children}
            {tooltip}
          </U21Spacer>
        );
      }
      if (tooltip) {
        return tooltip;
      }
      if (hasFullTextTooltip) {
        return children;
      }
      return '';
    })();

    return (
      <Container
        $inline={inline}
        $ellipsis={ellipsis}
        align={align}
        horizontal
        ref={ref}
        {...getDOMProps(rest)}
      >
        <StyledU21TypographyIcon
          $align={align}
          color={iconColor ?? color}
          variant={variant}
        >
          {icon}
        </StyledU21TypographyIcon>
        <U21Tooltip tooltip={displayTooltip} {...tooltipProps}>
          <StyledTypography
            $color={color}
            $ellipsis={ellipsis}
            component={htmlFor ? 'label' : component}
            // @ts-expect-error prop is valid because we force component to label if it exists
            htmlFor={htmlFor}
            variant={variant}
            align={textAlign}
            ref={internalRef}
            textTransform={textTransform}
          >
            {children}
          </StyledTypography>
        </U21Tooltip>
      </Container>
    );
  },
);

const Container = styled(U21Spacer)<{ $inline?: boolean; $ellipsis?: boolean }>`
  ${(props) =>
    props.$inline &&
    css`
      display: inline-flex;
    `}
  ${(props) =>
    props.$ellipsis &&
    css`
      overflow: hidden;
    `}
`;

const StyledU21TypographyIcon = styled(U21TypographyIcon)<{
  $align: U21SpacerProps['align'];
}>`
  ${(props) => {
    const {
      $align,
      theme: { typography },
      variant,
    } = props;
    const { remToPx } = typography;
    const properties = typography[variant!]; // variant is guaranteed to be defined here
    const { fontSize, lineHeight } = properties;
    const base = remToPx(fontSize);
    const small = remToPx(
      properties['@media (min-width:600px)']?.fontSize || fontSize,
    );
    const medium = remToPx(
      properties['@media (min-width:960px)']?.fontSize || fontSize,
    );
    const large = remToPx(
      properties['@media (min-width:1280px)']?.fontSize || fontSize,
    );
    switch ($align) {
      case 'start':
        return css`
          &&& {
            margin-top: ${(base * lineHeight - base) / 2}px;
            @media (min-width: 600px) {
              margin-top: ${(small * lineHeight - small) / 2}px;
            }
            @media (min-width: 960px) {
              margin-top: ${(medium * lineHeight - medium) / 2}px;
            }
            @media (min-width: 1280px) {
              margin-top: ${(large * lineHeight - large) / 2}px;
            }
          }
        `;
      case 'end':
        return css`
          &&& {
            margin-bottom: ${(base * lineHeight - base) / 2}px;
            @media (min-width: 600px) {
              margin-bottom: ${(small * lineHeight - small) / 2}px;
            }
            @media (min-width: 960px) {
              margin-bottom: ${(medium * lineHeight - medium) / 2}px;
            }
            @media (min-width: 1280px) {
              margin-bottom: ${(large * lineHeight - large) / 2}px;
            }
          }
        `;
      default:
        return css``;
    }
  }}
`;

interface ColorProps {
  $color: string;
  $ellipsis?: boolean;
  component?: TypographyProps['component'];
}

const StyledTypography = styled(Typography)<ColorProps>`
  ${COLOR_STYLE}

  ${(props) =>
    props.$ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
