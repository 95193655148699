import { useSelector, useDispatch } from 'react-redux';
import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import { IconCoin, IconSearch } from '@u21/tabler-icons';
import {
  U21InputGroup,
  U21Select,
  U21SelectSearch,
  U21SelectProps,
  U21SelectSearchProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';

// Actions
import { eventsSearch } from 'app/modules/search/actions';

// Selectors
import {
  selectEventsSearchResults,
  selectEventsSearchLoading,
} from 'app/modules/search/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';

// Constants
import { EVENTS_TYPE_OPTIONS } from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

// Utils
import permissions from 'app/shared/utils/permissions';

// Helpers
import { formatTransactionOptions } from 'app/modules/search/helpers';
import { uniqBy } from 'lodash';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { TransactionSearchOptions } from 'app/modules/search/enum';
import {
  searchTransaction,
  useTransactionSearch,
} from 'app/modules/transactions/queries/useTransactionSearch';
import { useQueryClient } from '@tanstack/react-query';
import { TransactionSearchPayload } from 'app/modules/search/payload';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';

export interface U21TransactionEventSearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: (number | string)[], e: SyntheticEvent) => void;
  value?: (number | string)[];
  selectProps?: Omit<
    U21SelectProps<string, false>,
    'disabled' | 'error' | 'onChange' | 'options' | 'ref' | 'as'
  >;
  searchProps?: Omit<
    U21SelectSearchProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
  additionalOptions?: U21SelectOptionProps[];
}

const selectHasReadEventsPermissions = selectHasPermissionsFactory(
  permissions.readEvents,
);

const TYPE_OPTIONS = EVENTS_TYPE_OPTIONS;

export const U21TransactionEventSearch: FC<U21TransactionEventSearchProps> = ({
  disabled,
  error,
  onChange,
  value = [],
  selectProps = {},
  searchProps = {},
  additionalOptions = [],
  clearable = true,
  ...rest
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [newPayload, setNewPayload] = useState<TransactionSearchPayload>();

  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);
  const { data: v1Data } = useSelector(selectEventsSearchResults);
  const v1Loading = useSelector(selectEventsSearchLoading);
  const hasReadEventsPermissions = useSelector(selectHasReadEventsPermissions);

  const [type, setType] = useState(TYPE_OPTIONS[0].value);

  const isV2SearchUsed = useMemo(() => {
    return isSearchV2Enabled && type === TransactionSearchOptions.EXTERNAL_ID;
  }, [isSearchV2Enabled, type]);

  const { transactions, isFetching } = useTransactionSearch(
    newPayload,
    isSearchV2Enabled,
  );

  const data = useMemo(
    () => (isV2SearchUsed ? transactions : v1Data),
    [transactions, isV2SearchUsed, v1Data],
  );

  const loading = useMemo(
    () => (isV2SearchUsed ? isFetching : v1Loading),
    [isFetching, isV2SearchUsed, v1Loading],
  );

  const options = useMemo(() => {
    return uniqBy(
      [...formatTransactionOptions(data), ...additionalOptions],
      'value',
    );
  }, [additionalOptions, data]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadEventsPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        if (isV2SearchUsed && type === TransactionSearchOptions.EXTERNAL_ID) {
          const payload = {
            search_field: TransactionSearchOptions.EXTERNAL_ID,
            phrases: missingValues.map(String),
            exact_match: true,
            offset: OFFSET_DEFAULT,
            limit: missingValues.length,
            all_phrases_must_match: false,
          };

          queryClient.prefetchQuery({
            queryKey: TRANSACTION_QUERY_KEYS.getTransactionSearch(payload),
            queryFn: () => searchTransaction(payload),
          });

          setNewPayload(payload);
        } else {
          missingValues.map((i) =>
            dispatch(
              eventsSearch({
                limit: INFINITE_SCROLL_LIMIT,
                offset: OFFSET_DEFAULT,
                phrase: String(i),
                type: 'external_id',
              }),
            ),
          );
        }
      }
    }
  }, [
    dispatch,
    hasReadEventsPermissions,
    isV2SearchUsed,
    options,
    queryClient,
    type,
    value,
  ]);

  const onSearch = useCallback(
    (query) => {
      if (query) {
        if (isV2SearchUsed) {
          setNewPayload({
            search_field: TransactionSearchOptions.EXTERNAL_ID,
            phrases: query ? [query] : [],
            exact_match: true,
            offset: OFFSET_DEFAULT,
            limit: INFINITE_SCROLL_LIMIT,
            all_phrases_must_match: false,
          });
        } else {
          dispatch(
            eventsSearch({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase: query || '',
              type,
            }),
          );
        }
      }
    },
    [dispatch, isV2SearchUsed, type],
  );

  if (!hasReadEventsPermissions) {
    return null;
  }

  return (
    <U21InputGroup disabled={disabled} error={error}>
      <StyledU21Select
        clearable={false}
        disabled={disabled}
        error={error}
        label="Search Event"
        onChange={(val: string) => setType(val)}
        options={TYPE_OPTIONS}
        searchable={false}
        startIcon={<IconCoin />}
        value={type}
        {...selectProps}
      />
      <StyledU21SelectSearch
        disabled={disabled}
        error={error}
        clearable={clearable}
        limitTags={1}
        loading={loading}
        multi
        onChange={onChange}
        options={options}
        onSearch={onSearch}
        placeholder="Any"
        startIcon={<IconSearch />}
        value={value}
        {...rest}
        {...searchProps}
      />
    </U21InputGroup>
  );
};

const StyledU21Select = styled(U21Select)`
  min-width: 200px;
  width: 200px;
`;

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;
