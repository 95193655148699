const permissions = {
  /**
   * @deprecated Do not use this permission since it is a permission to bypass all permissions
   */
  rootAll: 'root:all',

  // Admin dashboard
  editAdminDashboard: 'edit:admin_dashboard',
  editRulesAdminDashboard: 'edit:rules_admin_dashboard',
  editAiAdminDashboard: 'edit:ai_admin_dashboard',

  // Admin org permissions
  readOrgPermissions: 'read:org_permissions',
  editOrgPermissions: 'edit:org_permissions',

  // Admin org switcher
  adminOrgSwitcherPermissions: 'admin_org_switcher:all',

  // Permissions
  readPermissions: 'read:permissions',
  editPermissions: 'edit:permissions',

  // Cases
  readCases: 'read:cases',
  createCases: 'create:cases',
  editCases: 'edit:cases',

  // Settings
  editStorage: 'edit:storage',
  editNotificationSettings: 'edit:notification_settings',
  editAccountSettings: 'edit:account_settings',
  readCustomDataSettings: 'read:custom_data_settings',
  editCustomDataSettings: 'edit:custom_data_settings',

  // Deadlines
  editDeadlinesConfig: 'edit:deadlines_config',
  readDeadlinesConfig: 'read:deadlines_config',
  createDeadlinesConfig: 'create:deadlines_config',
  editDeadlines: 'edit:deadlines',

  // Uploads
  readDatafileUploads: 'read:datafile_uploads',
  createDatafileUploads: 'create:datafile_uploads',
  deleteDatafileUploads: 'delete:datafile_uploads',

  // Alerts
  createAlerts: 'create:alerts',
  readAlerts: 'read:alerts',
  editAlerts: 'edit:alerts',

  // Alert Component Buttons
  readAlertComponentButtons: 'read:alert_component_buttons',

  // Queues
  editQueues: 'edit:queues',

  // Rules
  readRules: 'read:rules',
  createRules: 'create:rules',
  editRules: 'edit:rules',
  ruleActivations: 'edit:rule_activations',

  // Agents
  readAgents: 'read:agents',
  editAgents: 'edit:agents',

  // Teams
  createTeams: 'create:teams',
  readTeams: 'read:teams',
  editTeams: 'edit:teams',
  deleteTeams: 'delete:teams',

  // Assignments
  readAssignments: 'read:assignments',

  // Entities
  readEntities: 'read:entities',
  editEntities: 'edit:entities',
  readEntityMedia: 'read:entity_media',
  readNetworkAnalysis: 'read:link_analysis',

  // PII Censorship Self-serve
  /**
   * @deprecated censorship now uses custom data setting permissions
   */
  readCensorshipConditions: 'read:censorship_conditions',
  /**
   * @deprecated censorship now uses custom data setting permissions
   */
  createCensorshipConditions: 'create:censorship_conditions',
  /**
   * @deprecated censorship now uses custom data setting permissions
   */
  editCensorshipConditions: 'edit:censorship_conditions',
  /**
   * @deprecated censorship now uses custom data setting permissions
   */
  deleteCensorshipConditions: 'delete:censorship_conditions',

  // Devices
  /**
   * @deprecated Devices is deprecated
   */
  readDevices: 'read:devices',
  /**
   * @deprecated Devices is deprecated
   */
  editDevices: 'edit:devices',

  // Events
  editEvents: 'edit:events',
  readEvents: 'read:events',

  // Txn Instruments
  readTxnInstruments: 'read:txn_instruments',
  editTxnInstruments: 'edit:txn_instruments',
  editLockUnlockTxnInstruments: 'edit:lock_unlock_txn_instruments',
  editDeactivateTxnInstruments: 'edit:deactivate_txn_instruments',

  // SAR (Suspicious Activity Report)
  readSars: 'read:sars',
  createSars: 'create:sars',
  editSars: 'edit:sars',
  editSarsConfig: 'edit:sars_config',
  submitSars: 'submit:sars',

  // Risk ratings
  createRiskRatings: 'create:risk_ratings',
  readRiskRatings: 'read:risk_ratings',
  editRiskRatings: 'edit:risk_ratings',

  // Api Keys
  createApiKeys: 'create:api_keys',
  readApiKeys: 'read:api_keys',
  deleteApiKeys: 'delete:api_keys',

  // Dashboard
  createDashboards: 'create:dashboards',
  editDashboards: 'edit:dashboards',
  deleteDashboards: 'delete:dashboards',

  // Insights
  readInsights: 'read:insights',

  // Webhooks
  readWebhooks: 'read:webhooks',
  editWebhooks: 'edit:webhooks',
  createWebhooks: 'create:webhooks',
  deleteWebhooks: 'delete:webhooks',

  readBlacklists: 'read:blacklists',
  editBlacklists: 'edit:blacklists',
  createBlacklists: 'create:blacklists',
  deleteBlacklists: 'delete:blacklists',

  create314aScans: 'create:314a_scans',

  // Quality Cycles
  readQuality: 'read:quality',
  editQuality: 'edit:quality',

  // Database Connections
  readDDBConnections: 'read:ddb_connections',
  editDDBConnections: 'edit:ddb_connections',
  createDDBConnections: 'create:ddb_connections',
  deleteDDBConnections: 'delete:ddb_connections',

  // PCI Compliance
  readPans: 'read:pans',

  // Tags
  editTags: 'edit:tags',

  adminAPI: 'admin_api:all',

  systemExports: 'read:system_exports',
} as const;

export default permissions;

export type Permission = ValueOf<typeof permissions>;
