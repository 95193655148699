import { TableConfig } from 'app/shared/pagination/models';

export const DEFAULT_AI_ADMIN_TRACKING_TABLE_CONFIG: TableConfig[] = [
  { key: 'id', type: 'text', label: 'ID', width: 200 },
  { key: 'org_id', type: 'text', label: 'Organization ID', width: 200 },
  {
    key: 'ai_investigations_result_id',
    type: 'text',
    label: 'AI investigation result ID',
    width: 200,
  },
  { key: 'created_at', type: 'datetime', label: 'Created at', width: 200 },
  { key: 'updated_at', type: 'datetime', label: 'Updated at', width: 200 },
  { key: 'group_id', type: 'text', label: 'Group ID', width: 200 },
  { key: 'framework', type: 'text', label: 'Framework', width: 200 },
  { key: 'model_name', type: 'text', label: 'Model', width: 200 },
  { key: 'query_type', type: 'text', label: 'Query type', width: 200 },
  { key: 'tool_name', type: 'text', label: 'Tool name', width: 200 },
  { key: 'prompt_version', type: 'text', label: 'Prompt version', width: 200 },
  { key: 'prompt_name', type: 'text', label: 'Prompt name', width: 200 },
  {
    key: 'prompt_template',
    type: 'text',
    label: 'Prompt template',
    width: 200,
  },
  {
    key: 'prompt_variables',
    type: 'text',
    label: 'Prompt variables',
    width: 200,
  },
  { key: 'raw_prompt', type: 'text', label: 'Raw prompt', width: 200 },
  { key: 'raw_response', type: 'text', label: 'Raw response', width: 200 },
  {
    key: 'processed_response',
    type: 'text',
    label: 'Processed response',
    width: 200,
  },
  { key: 'tokens_used', type: 'text', label: 'Tokens used', width: 200 },
  { key: 'input_tokens', type: 'text', label: 'Input tokens', width: 200 },
  { key: 'output_tokens', type: 'text', label: 'Output tokens', width: 200 },
  { key: 'cost', type: 'text', label: 'Cost', width: 200 },
  { key: 'latency_ms', type: 'text', label: 'Latency (ms)', width: 200 },
  { key: 'status', type: 'text', label: 'Status', width: 200 },
  { key: 'error_message', type: 'text', label: 'Error message', width: 200 },
  { key: 'quality_score', type: 'text', label: 'Quality score', width: 200 },
  { key: 'feedback', type: 'text', label: 'Feedback', width: 200 },
  { key: 'meta', type: 'text', label: 'Meta', width: 200 },
  { key: 'tags', type: 'text_array', label: 'Tags', width: 200 },
];
