// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { uniqBy } from 'lodash';

// Types
import { AlertResponse } from 'app/modules/alerts/types';

// Models
import { AlertsActionTypes, AlertsState } from 'app/modules/alerts/models';
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';
import { EventActionTypes } from 'app/shared/events/models';

// Actions
import { DeadlinesActions } from 'app/modules/deadlines/actions';
import { AlertsActions } from 'app/modules/alerts/actions';
import { EventsActions } from 'app/shared/events/actions';

// Constants
import { INITIAL_ALERT } from 'app/modules/alerts/constants';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<AlertsState> = {
  alerts: [],
  alert: { ...INITIAL_ALERT },
  alertsCount: 0,
  alertsEntitiesCount: 0,
  alertsInstrumentsCount: 0,
  myAlertsStats: {
    open_alerts_count: 0,
    alerts_completed_last_24_hrs_count: 0,
  },
  alertsLastCount: 0,
  alertQueues: [],
  histogram: {
    histogram_data: {
      in: [],
      out: [],
    },
    transaction_codes: [],
  },
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<AlertsState> = (
  state = initialState,
  action: AlertsActions | EventsActions | DeadlinesActions,
) => {
  return produce(state, (draft: Draft<AlertsState>) => {
    // When making some change, it may apply to the primary alert, sidebar
    // alert, or some row in a table, this method groups it all together.
    // Can be used for a single alert or a list of updated alerts
    const modifyAlerts = (updatedValues: AlertResponse | AlertResponse[]) => {
      const newAlerts = {};
      if (Array.isArray(updatedValues)) {
        updatedValues.forEach((newAlert) => {
          newAlerts[newAlert.id] = newAlert;
        });
      } else {
        newAlerts[updatedValues.id] = updatedValues;
      }

      // Updating alert details
      if (newAlerts[draft.alert.id]) {
        draft.alert = {
          ...draft.alert,
          ...newAlerts[draft.alert.id],
        };
      }

      // Updating table alerts
      draft.alerts = draft.alerts.map((alert) => {
        if (newAlerts[alert.id]) {
          return {
            ...alert,
            ...newAlerts[alert.id],
          };
        }
        return alert;
      });
    };

    switch (action.type) {
      case AlertsActionTypes.RETRIEVE_ALERT_SUCCESS:
      case AlertsActionTypes.RETRIEVE_ALERT_DETAILS_SUCCESS:
        draft.alert = action.payload;
        modifyAlerts(action.payload);
        return;

      case AlertsActionTypes.RETRIEVE_MY_ALERTS_STATS_SUCCESS:
        draft.myAlertsStats = action.payload;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_TRANSACTIONS_SUCCESS:
        draft.alert.events = action.payload;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_AUDIT_TRAIL_SUCCESS:
        draft.alert.audit_trail = action.payload;
        return;

      case AlertsActionTypes.CLEAR_ALERT_ENTITIES:
        draft.alert.entities = initialState.alert.entities;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_ENTITIES_SUCCESS:
        draft.alert.entities = action.payload.entities;
        draft.alert.paginatedEntities = action.payload;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_INSTRUMENTS_SUCCESS:
        draft.alert.instruments = action.payload.instruments;
        draft.alert.paginatedInstruments = action.payload;
        return;

      case AlertsActionTypes.RETRIEVE_ALERTS_ALERT_COUNT_SUCCESS:
        draft.alertsCount = action.payload.count;
        draft.alertsEntitiesCount = action.payload.entity_count;
        draft.alertsInstrumentsCount = action.payload.instrument_count;
        return;

      case AlertsActionTypes.RETRIEVE_ALERTS_SUCCESS:
        draft.alerts = uniqBy(
          [
            ...(action.payload.append ? draft.alerts : []),
            ...action.payload.alerts,
          ],
          (actionVar) => actionVar.id || JSON.stringify(actionVar),
        );
        draft.alertsLastCount = action.payload.alerts.length;
        draft.alertsCount = action.payload.count;
        return;

      // Similar to RETRIEVE_ALERTS_SUCCESS except new alerts are inserted at start of list
      case AlertsActionTypes.APPEND_TO_TOP_OF_ALERTS:
        draft.alerts = uniqBy(
          [...action.payload.alerts, ...draft.alerts],
          (actionVar) => actionVar.id || JSON.stringify(actionVar),
        );
        draft.alertsLastCount = action.payload.alerts.length;
        draft.alertsCount = action.payload.count;
        return;

      case AlertsActionTypes.CLEAR_ALERTS_LIST:
        draft.alerts = initialState.alerts;
        draft.alertsCount = initialState.alertsCount;
        return;

      case AlertsActionTypes.TRIGGER_ALERT_ACTION_SUCCESS:
      case AlertsActionTypes.UPDATE_ALERT_CACHE:
        modifyAlerts(action.payload);
        return;

      case AlertsActionTypes.CHANGE_ALERT_COMPONENT_STATUS_SUCCESS:
        modifyAlerts(action.payload);
        return;

      case AlertsActionTypes.REASSIGN_ALERTS_SUCCESS:
        modifyAlerts(action.payload.assignments);
        return;

      case AlertsActionTypes.REQUEUE_ALERTS_SUCCESS:
        modifyAlerts(action.payload.alerts);
        return;

      case EventActionTypes.EDIT_EVENT_SUCCESS:
        draft.alert.events = (draft.alert.events || []).map((event) => {
          if (event.id === action.payload.id) {
            return action.payload;
          }
          return event;
        });
        return;

      case AlertsActionTypes.EDIT_ALERT_SUCCESS:
        // The API returns a weird partial object that contains alert
        // values. Since `modifyAlerts` spreads the original alert
        // in addition to the new values, this is fine for now
        modifyAlerts(action.payload as any);
        return;

      case AlertsActionTypes.ADD_DOCUMENTS_SUCCESS:
      case AlertsActionTypes.EDIT_DOCUMENT_SUCCESS:
        draft.alert.attachments = action.payload.attachments;
        return;

      case AlertsActionTypes.DELETE_DOCUMENT_SUCCESS:
        draft.alert.attachments = action.payload.attachments;
        return;

      case AlertsActionTypes.ASSOCIATE_ALERTS_WITH_CASES_SUCCESS:
        // only update if page has not changed
        if (!action.payload.alertsPageChanged) {
          draft.alert.case_ids = Array.from(
            new Set([...draft.alert.case_ids, ...action.payload.case_ids]),
          );
        }
        return;

      case DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE_SUCCESS:
        if (action.payload.article_id === draft.alert.id) {
          draft.alert.deadline = action.payload;
        }
        draft.alerts = draft.alerts.map((alert) => {
          if (alert.id === action.payload.article_id) {
            return {
              ...alert,
              deadline: action.payload,
            };
          }
          return alert;
        });
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_QUEUES_SUCCESS:
      case AlertsActionTypes.RETRIEVE_ALL_ALERT_QUEUES_SUCCESS:
        draft.alertQueues = action.payload.queues;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_HISTOGRAM_SUCCESS:
        draft.histogram = action.payload;
        return;

      case AlertsActionTypes.RETRIEVE_ALERT_ACTION_EVENTS_SUCCESS:
        draft.alert.action_events = action.payload;
        return;

      default:
        return;
    }
  });
};

export { reducer as alertsReducer, initialState };
