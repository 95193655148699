import { TransactionSearchPayload } from 'app/modules/search/payload';
import { GetTransactionsPayload } from 'app/modules/transactions/requests';

const BASE = ['transactions'];

export const TRANSACTION_QUERY_KEYS = {
  getTransactions: (payload: GetTransactionsPayload) =>
    [...BASE, payload] as const,
  getTransactionById: (id?: string) => [...BASE, id] as const,
  getCheckImageTransactions: (id?: string) =>
    [...BASE, 'checkImageTransaction', id] as const,
  getTransactionSearch: (payload?: TransactionSearchPayload) =>
    payload
      ? ([...BASE, 'search', payload] as const)
      : ([...BASE, 'search'] as const),
};
