import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import { IconBriefcase } from '@u21/tabler-icons';
import {
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';
import { InvestigationType } from 'app/modules/investigations/models';
import { useListCases } from 'app/modules/cases/queries/useListCases';

interface Props {}

export type U21CaseSelectProps<TClearable extends boolean> = DistributiveOmit<
  U21SelectSearchProps<number, TClearable>,
  'options' | 'onSearch'
> &
  Props;

export const U21CaseSelect = <TClearable extends boolean>(
  props: U21CaseSelectProps<TClearable>,
) => {
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const [phrase, setPhrase] = useState('');
  const { data: cases, isFetching: casesLoading } = useListCases({
    object_type: InvestigationType.CASE,
    object_filters: {
      limit: INFINITE_SCROLL_LIMIT,
      offset: OFFSET_DEFAULT,
      phrase,
    },
  });

  const options: U21SelectOptionProps[] = useMemo(() => {
    return (
      cases?.cases.map((c) => ({
        text: c.title,
        value: c.id,
        description: c.description,
      })) ?? []
    );
  }, [cases]);

  if (!hasReadCasesPermission) {
    return null;
  }

  return (
    <U21SelectSearch
      options={options}
      onSearch={(newPhrase = '') => {
        setPhrase(newPhrase);
      }}
      startIcon={<IconBriefcase />}
      loading={casesLoading}
      {...props}
    />
  );
};
