import {
  GA_FEATURES,
  GaFeatureTypes,
} from 'app/shared/components/GaBanner/constants';
import { getFeatureDisabledLSKey } from 'app/shared/components/GaBanner/utils';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

const GA_BANNER_HIDDEN_LS_PREFIX = '__GA_BANNER_HIDDEN_';
const PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX = '__PRE_RELEASE_BANNER_HIDDEN_';
const EARLY_ACCESS_ENABLED_LS_PREFIX = '__EARLY_ACCESS_ENABLED_';
const EARLY_ACCESS_OPT_IN_HIDDEN_LS_PREFIX = '__EARLY_ACCESS_OPT_IN_HIDDEN_';
const EARLY_ACCESS_OPT_OUT_HIDDEN_LS_PREFIX = '__EARLY_ACCESS_OPT_OUT_HIDDEN_';

type InitialState = Record<
  GaFeatureTypes,
  {
    featureDisabled: boolean;
    gaBannerHidden: boolean;
    preReleaseBannerHidden: boolean;
    earlyAccess: boolean;
    earlyAccessOptInHidden: Date | null;
    earlyAccessOptOutHidden: boolean;
  }
>;
const initialState = GA_FEATURES.reduce<InitialState>((acc, feature) => {
  const earlyAccessOptInHidden = localStorage.getItem(
    `${EARLY_ACCESS_OPT_IN_HIDDEN_LS_PREFIX}${feature}`,
  );
  acc[feature] = {
    featureDisabled:
      localStorage.getItem(getFeatureDisabledLSKey(feature)) === 'true',
    gaBannerHidden:
      localStorage.getItem(`${GA_BANNER_HIDDEN_LS_PREFIX}${feature}`) ===
      'true',
    preReleaseBannerHidden:
      localStorage.getItem(
        `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${feature}`,
      ) === 'true',
    earlyAccess:
      localStorage.getItem(`${EARLY_ACCESS_ENABLED_LS_PREFIX}${feature}`) ===
      'true',
    earlyAccessOptInHidden:
      earlyAccessOptInHidden && !isNaN(Number(new Date(earlyAccessOptInHidden)))
        ? new Date(earlyAccessOptInHidden)
        : // treat empty / invalid opt in hidden date as > 14 days so banner triggers
          new Date(0),
    earlyAccessOptOutHidden:
      localStorage.getItem(
        `${EARLY_ACCESS_OPT_OUT_HIDDEN_LS_PREFIX}${feature}`,
      ) === 'true',
  };
  return acc;
}, {} as InitialState);

const GA_BANNER_NAME = 'gaBannerSlice';

export const toggleHideGABanner = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hideBanner: boolean },
  { featureName: GaFeatureTypes; hideBanner: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_GA_BANNER`, (payload) => {
  if (payload.hideBanner) {
    localStorage.setItem(
      `${GA_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.hideBanner),
    );
  } else {
    localStorage.removeItem(
      `${GA_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

export const toggleHidePreReleaseBanner = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hideBanner: boolean },
  { featureName: GaFeatureTypes; hideBanner: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_PRE_RELEASE_BANNER`, (payload) => {
  if (payload.hideBanner) {
    localStorage.setItem(
      `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.hideBanner),
    );
  } else {
    localStorage.removeItem(
      `${PRE_RELEASE_BANNER_HIDDEN_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

export const toggleDisableNewFeature = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; featureDisabled: boolean },
  { featureName: GaFeatureTypes; featureDisabled: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_NEW_FEATURE`, (payload) => {
  if (payload.featureDisabled) {
    localStorage.setItem(
      getFeatureDisabledLSKey(payload.featureName),
      JSON.stringify(payload.featureDisabled),
    );
  } else {
    localStorage.removeItem(getFeatureDisabledLSKey(payload.featureName));
  }
  return payload;
});

export const toggleEarlyAccess = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; enabled: boolean },
  { featureName: GaFeatureTypes; enabled: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_EARLY_ACCESS`, (payload) => {
  if (payload.enabled) {
    localStorage.setItem(
      `${EARLY_ACCESS_ENABLED_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.enabled),
    );
  } else {
    localStorage.removeItem(
      `${EARLY_ACCESS_ENABLED_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

export const toggleEarlyAccessOptIn = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hide: boolean },
  { featureName: GaFeatureTypes; date: Date }
>(`${GA_BANNER_NAME}/TOGGLE_EARLY_ACCESS_OPT_IN`, (payload) => {
  if (payload.hide) {
    const date = new Date();
    localStorage.setItem(
      `${EARLY_ACCESS_OPT_IN_HIDDEN_LS_PREFIX}${payload.featureName}`,
      date.toISOString(),
    );
    return { date, featureName: payload.featureName };
  }
  localStorage.removeItem(
    `${EARLY_ACCESS_OPT_IN_HIDDEN_LS_PREFIX}${payload.featureName}`,
  );
  // treat empty / invalid opt in hidden date as > 14 days so banner triggers
  return { date: new Date(0), featureName: payload.featureName };
});

export const toggleEarlyAccessOptOut = u21CreateAsyncThunk<
  { featureName: GaFeatureTypes; hide: boolean },
  { featureName: GaFeatureTypes; hide: boolean }
>(`${GA_BANNER_NAME}/TOGGLE_EARLY_ACCESS_OPT_OUT`, (payload) => {
  if (payload.hide) {
    localStorage.setItem(
      `${EARLY_ACCESS_OPT_OUT_HIDDEN_LS_PREFIX}${payload.featureName}`,
      JSON.stringify(payload.hide),
    );
  } else {
    localStorage.removeItem(
      `${EARLY_ACCESS_OPT_OUT_HIDDEN_LS_PREFIX}${payload.featureName}`,
    );
  }
  return payload;
});

const gaBannerSlice = u21CreateSlice({
  name: GA_BANNER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toggleHideGABanner.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].gaBannerHidden = payload.hideBanner;
      })
      .addCase(toggleHidePreReleaseBanner.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].preReleaseBannerHidden = payload.hideBanner;
      })
      .addCase(toggleDisableNewFeature.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].featureDisabled = payload.featureDisabled;
      })
      .addCase(toggleEarlyAccess.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].earlyAccess = payload.enabled;
      })
      .addCase(toggleEarlyAccessOptIn.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].earlyAccessOptInHidden = payload.date;
      })
      .addCase(toggleEarlyAccessOptOut.fulfilled, (draft, { payload }) => {
        draft[payload.featureName].earlyAccessOptOutHidden = payload.hide;
      });
  },
});

export const { name, reducer } = gaBannerSlice;
