import { OFFSET_DEFAULT } from 'app/shared/constants';

import { formatInstrumentOptions } from 'app/modules/search/helpers';
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { InstrumentSearchRequest } from 'app/modules/search/models';
import {
  searchInstruments,
  useSearchInstruments,
} from 'app/modules/search/queries/useSearchInstruments';
import { useQueryClient } from '@tanstack/react-query';
import { SEARCH_QUERY_KEYS } from 'app/modules/search/queries/keys';

interface InstrumentFilterDisplayProps {
  externalIds: string[];
  label: string;
}

export const InstrumentFilterDisplay = ({
  externalIds,
  label,
}: InstrumentFilterDisplayProps) => {
  const queryClient = useQueryClient();
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );

  const { instruments } = useSearchInstruments();

  const instrumentOptionsMap = useMemo(
    () => formatInstrumentOptions(instruments, 'external_id'),
    [instruments],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [externalIds]);

  useEffect(() => {
    if (hasReadInstrumentsPermissions) {
      const missingValues = externalIds.filter(
        (i) => !instrumentOptionsMap.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        const searchPayload: InstrumentSearchRequest = {
          phrase: '',
          limit: missingValues.length,
          offset: OFFSET_DEFAULT,
          type: 'external_id',
          external_ids: missingValues,
        };

        queryClient.prefetchQuery({
          queryKey: SEARCH_QUERY_KEYS.searchInstruments(searchPayload),
          queryFn: () => searchInstruments(searchPayload),
        });
      }
    }
  }, [
    externalIds,
    hasReadInstrumentsPermissions,
    instrumentOptionsMap,
    queryClient,
  ]);

  const values = useMemo(
    () => externalIds.map((i) => instrumentOptionsMap[i]?.text || i),
    [instrumentOptionsMap, externalIds],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};
