import { useQuery } from '@tanstack/react-query';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';
import { retrieveInvestigationsCases } from 'app/modules/investigations/api';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import { selectDLEnabledForCaseList } from 'app/shared/featureFlags/selectors';
import { post } from 'app/shared/utils/fetchr';

export const useListCases = (payload: InvestigationsPayload) => {
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const useNewEndpoint = useSelector(selectDLEnabledForCaseList);

  return useQuery({
    queryKey: CASE_QUERY_KEYS.listCases(payload, useNewEndpoint),
    queryFn: () =>
      useNewEndpoint
        ? post<InvestigationsCaseResponse>(
            '/cases/cached/list',
            payload.object_filters,
          )
        : retrieveInvestigationsCases<InvestigationsCaseResponse>(payload),
    enabled: hasReadCasesPermission,
  });
};
