import { InvestigationsPayload } from 'app/modules/investigations/types';

const BASE = ['cases'] as const;

export const CASE_QUERY_KEYS = {
  getCase: (payload: number, useNewEndpoint: boolean = false) => {
    if (useNewEndpoint) {
      return [...BASE, 'case-cached', payload] as const;
    }
    return [...BASE, 'case', payload] as const;
  },
  listCases: (payload: InvestigationsPayload, useNewEndpoint?: boolean) =>
    useNewEndpoint
      ? ([...BASE, 'list-cache', payload] as const)
      : ([...BASE, 'list', payload] as const),
};
