import produce, { Draft } from 'immer';
import { cloneDeep } from 'lodash';

// Actions
import GoAMLReduxActions from 'app/modules/goAML/actions';
import { DeadlinesActions } from 'app/modules/deadlines/actions';

// Models
import {
  GoAMLState,
  GoAMLActionTypes,
  GoAMLFilingDetails,
  GoAMLErrorsDictionary,
} from 'app/modules/goAML/models';
import {
  ReportType,
  SarStatus,
  SarSummary,
} from 'app/modules/fincenSar/models';
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

// Constants
import { EMPTY_CONTENT } from 'app/modules/goAML/schemas/templates/emptyGoAMLContent';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import { showError, invertIndexes } from 'app/modules/goAML/helpers';

const EMPTY_FILING: GoAMLFilingDetails = {
  org_id: -1,
  first_alert_created_at: '',
  deadline: null,
  deadline_config: null,
  name: '',
  description: '',
  id: -1,
  created_at: '',
  filing_type: 'Initial report',
  status: SarStatus.PREFILING_VALIDATION_PENDING,
  updated_at: '',
  attachments: [],
  cases: [],
  alerts: [],
  lock: false,
  comments_count: 0,
  comments: [],
  submitted: false,
  report_type: ReportType.GOAML_STR,
  created_by: {
    full_name: 'Test K',
  },
  error_msg: '',
  queue: null,
  content: cloneDeep(EMPTY_CONTENT),
  activity_type: [],
  entity_region: [],
  is_encrypted: false,
};

export const initialState: GoAMLState = {
  filings: {
    filings: [],
    num_filings: 0,
  },
  filing: EMPTY_FILING,
  validationErrors: {},
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer = (
  state = initialState,
  action: GoAMLReduxActions | DeadlinesActions,
) => {
  return produce(state, (draft: Draft<GoAMLState>) => {
    switch (action.type) {
      case GoAMLActionTypes.RETRIEVE_GOAML_FILINGS_SUCCESS:
        draft.filings = action.payload;
        break;
      case GoAMLActionTypes.RETRIEVE_GOAML_FILING_SUCCESS:
      case GoAMLActionTypes.CREATE_GOAML_FILING_SUCCESS:
      case GoAMLActionTypes.EDIT_GOAML_FILING_SUCCESS:
        draft.filing = action.payload;
        draft.validationErrors = initialState.validationErrors;
        break;
      case GoAMLActionTypes.VALIDATE_GOAML_FILING_SUCCESS: {
        draft.filing = action.payload;
        const validationErrors =
          action.payload.error_msgs?.reduce(
            (acc, errObj) => ({
              ...acc,
              [errObj.path]: showError(errObj.message),
            }),
            {} as GoAMLErrorsDictionary,
          ) || {};
        draft.validationErrors = invertIndexes(draft.filing, validationErrors);
        break;
      }
      case GoAMLActionTypes.ADD_GOAML_ATTACHMENTS_SUCCESS:
        draft.filing.attachments = draft.filing.attachments.concat(
          action.payload.attachments,
        );
        return;
      case GoAMLActionTypes.DELETE_GOAML_ATTACHMENT_SUCCESS:
        draft.filing.attachments = draft.filing.attachments?.filter(
          (attachments) => attachments.id !== Number(action.payload),
        );
        return;
      case GoAMLActionTypes.ARCHIVE_SAR_SUCCESS: {
        const sarSummary: SarSummary = action.payload as SarSummary;
        draft.filings.filings = draft.filings.filings.map((filing) => {
          if (filing.id === sarSummary.id) {
            return {
              ...filing,
              status: sarSummary.status,
            };
          }
          return filing;
        });
        return;
      }
      case DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE_SUCCESS:
        if (action.payload.article_id === draft.filing.id) {
          draft.filing.deadline = action.payload;
        }
        draft.filings.filings = draft.filings.filings.map((filing) => {
          if (filing.id === action.payload.article_id) {
            return {
              ...filing,
              deadline: action.payload,
            };
          }
          return filing;
        });
        return;
      default:
        return;
    }
  });
};

export { reducer as goAMLReducer };
