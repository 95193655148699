import { IconFile } from '@u21/tabler-icons';
import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';

export interface U21AttachmentChipProps
  extends Omit<U21ChipProps, 'id' | 'children'> {
  id: number | string;
  label: string;
}

export const U21AttachmentChip = (props: U21AttachmentChipProps) => {
  const { id, label, onClick, ...rest } = props;

  return (
    <U21Chip icon={<IconFile />} {...rest} onClick={onClick}>
      {label}
    </U21Chip>
  );
};
