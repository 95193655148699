import { createSelector } from 'reselect';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';

export const selectSidebarDefinition = (state: RootState) =>
  state.sidebar.definition;

export const selectSidebarOpen = createSelector(
  selectSidebarDefinition,
  (definition) => !!definition,
);

export const selectUsePopup = (state: RootState) => state.sidebar.usePopup;

export const selectUsePopupSidebar = createSelector(
  selectSidebarDefinition,
  selectUsePopup,
  (definition, usePopup) => definition?.coordinates && usePopup,
);

export const selectSidebarAlertID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.ALERT
      ? definition.data.alert.id
      : undefined,
);

export const selectSidebarCaseID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.CASE
      ? definition.data.id
      : undefined,
);

export const selectSidebarFinCENSARID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.SAR
      ? definition.data.id
      : undefined,
);

export const selectSidebarEntityID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.ENTITY
      ? definition.data.id
      : undefined,
);

export const selectSidebarInstrumentID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.INSTRUMENT
      ? definition.data.id
      : undefined,
);

export const selectSidebarTransactionID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.TRANSACTION
      ? definition.data.id
      : undefined,
);

export const selectSidebarActionEventID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.ACTION_EVENT
      ? definition.data.id
      : undefined,
);

export const selectSidebarAiAdminTrackingID = createSelector(
  selectSidebarDefinition,
  (definition) =>
    definition?.type === SidebarComponentTypes.AI_ADMIN
      ? definition.data.content.id
      : undefined,
);
