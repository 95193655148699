import { HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { RadioGroup, RadioProps } from '@mui/material';
import {
  U21Radio,
  U21RadioProps,
  U21RadioValue,
} from 'app/shared/u21-ui/components/input/radio/U21Radio';
import {
  GRID_STYLE,
  GridStyleProps,
} from 'app/shared/u21-ui/components/layout/U21Grid';

export interface U21RadioGroupOptionProps<TValue extends U21RadioValue>
  extends Omit<U21RadioProps<TValue>, 'checked' | 'onChange'> {
  label: ReactNode;
}

export interface U21RadioGroupProps<TValue extends U21RadioValue>
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
  columnMinWidth?: number;
  columns?: number;
  disabled?: boolean;
  horizontal?: boolean;
  inputProps?: RadioProps['inputProps'];
  onChange: (value: TValue, e: SyntheticEvent) => void;
  options: U21RadioGroupOptionProps<TValue>[];
  value?: TValue;
}

export const U21RadioGroup = <TValue extends U21RadioValue>({
  columnMinWidth = 200,
  columns = 1,
  disabled,
  horizontal,
  inputProps = {},
  onChange,
  options,
  value,
  ...rest
}: U21RadioGroupProps<TValue>) => {
  return (
    <StyledRadioGroup
      $columns={columns}
      $minWidth={columnMinWidth}
      // U21Radio already includes spacing so not needed
      $spacing={0}
      row={horizontal}
      value={value ?? ''}
      {...getDOMProps(rest)}
    >
      {options.map((option) => {
        const {
          disabled: optionDisabled,
          inputProps: optionInputProps,
          tooltip,
          value: optionValue,
          ...restOption
        } = option;
        return (
          <U21Radio
            key={optionValue}
            disabled={disabled || optionDisabled}
            inputProps={{ ...inputProps, ...optionInputProps }}
            onChange={(_, e) => onChange(optionValue, e)}
            tooltip={tooltip}
            value={optionValue}
            {...restOption}
          />
        );
      })}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(RadioGroup)<GridStyleProps>`
  ${(props) => (props.$columns > 1 ? GRID_STYLE : css``)}
`;
