// Types
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { ShortEntityResponse } from 'app/modules/entities/types';

// Models
import {
  EntitiesSearchResult,
  AlertsSearchResult,
  RulesSearchResult,
  EventsSearchResult,
  InstrumentsSearchResult,
  TeamsSearchResult,
  CasesSearchResult,
  SearchResultProps,
} from 'app/modules/search/models';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';

// Constants
import { CUSTOM_DATA_SEPARATOR } from 'app/shared/constants';

// Components
import {
  IconBuilding,
  IconUser,
  IconCoin,
  IconLock,
  IconUsers,
  IconFlag,
  IconBriefcase,
  IconCreditCard,
  IconScale,
} from '@u21/tabler-icons';
import { EntitySource, TransactionSource } from 'app/modules/search/response';

export enum ValueField {
  ID = 'ID',
  EXTERNAL_ID = 'EXTERNAL_ID',
}

export const formatEntityTopBarSearch = (
  entity: EntitiesSearchResult,
): SearchResultProps => {
  const { email, id, type } = entity;
  const externalId = entity.external_id;
  const firstName = entity.first_name;
  const middleName = entity.middle_name;
  const lastName = entity.last_name;
  const businessName = entity.name;
  const customData = entity.custom_data;

  let title = '';
  let description = (email || []).join(', ') || '';

  if (businessName) {
    title += businessName;
  }

  if (firstName) {
    // added for spacing
    title += ` ${firstName}`;
  }

  if (middleName) {
    // added for spacing
    title += ` ${middleName}`;
  }

  if (lastName) {
    // added for spacing
    title += ` ${lastName}`;
  }

  // if title is still empty, add external id as main title
  if (title.length <= 0) {
    title = externalId;
    // otherwise add external id to the side description
  } else {
    // added for spacing
    description += ` ${externalId}`;
  }

  if (customData && customData.includes('customer_raw')) {
    const customDataObj = JSON.parse(customData);
    let customDataStr = '';
    Object.entries(customDataObj.customer_raw).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        customDataStr += `${key} : ${value}`;
      } else if (typeof value === 'object' && value) {
        // value truthy check is to catch null
        Object.entries(value).forEach(([innerKey, innerValue]) => {
          if (
            typeof innerValue === 'string' ||
            typeof innerValue === 'number'
          ) {
            customDataStr += `${innerKey} : ${innerValue}`;
          }
        });
      }
    });
    if (customDataStr !== '') {
      description += ` ${CUSTOM_DATA_SEPARATOR} ${customDataStr}`;
    }
  }

  // This was added at a later date, name_readable is the preferred label & is calculated in the BackEnd to allow sorting/cleanliness,
  // the above logic for determining labels was not removed to avoid breaking any legacy problems
  const nameReadable = entity.name_readable;
  if (nameReadable) {
    title = nameReadable;
  }

  return {
    value: id,
    title,
    description,
    label: type,
    icon: <IconUser />,
    url: ROUTES_MAP.entitiesId.path.replace(':id', String(entity.id)),
  };
};

export const formatEntityOptions = (
  entities: EntitiesSearchResult[] | ShortEntityResponse[] | EntitySource[],
  searchType?: string,
  valueField?: ValueField,
): U21SelectOptionProps[] => {
  return entities.map((i) => {
    const {
      email,
      external_id: entityId,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      name_readable: nameReadable,
      id,
      name,
      type,
    } = i;

    let text;
    if (searchType === 'email_address') {
      text = email;
    } else if (nameReadable) {
      text = nameReadable;
    } else {
      text = name || '';
      if (firstName) {
        text += ` ${firstName}`;
      }
      if (middleName) {
        text += ` ${middleName}`;
      }
      if (lastName) {
        text += ` ${lastName}`;
      }
      text = text.trim();
    }

    const prefix = searchType === 'unit21_id' ? `#${id}` : entityId;
    return {
      icon: type === 'BUSINESS' ? <IconBuilding /> : <IconUser />,
      text: text ? `${prefix} - ${text}` : prefix,
      value: valueField === ValueField.EXTERNAL_ID ? entityId : id,
    };
  });
};

export const formatTeamTopBarSearch = (
  team: TeamsSearchResult,
): SearchResultProps => {
  const { id, name, description } = team;
  return {
    value: id,
    title: `#${id} - ${name}`,
    description,
    icon: <IconUsers />,
    url: ROUTES_MAP.teamsId.path.replace(':id', String(id)),
  };
};

export const formatAlertTopBarSearch = (
  alert: AlertsSearchResult,
): SearchResultProps => {
  const {
    id,
    title,
    description,
    status,
    queue_id: queueId,
    queue_access_type: queueAccessType,
  } = alert;
  return {
    value: id,
    title: `#${id} - ${title}`,
    description,
    label: status,
    icon: <IconFlag />,
    url: ROUTES_MAP.alertsId.path.replace(':id', String(id)),
    queueId,
    queueAccessType,
  };
};

export const formatCaseTopBarSearch = (
  caseResult: CasesSearchResult,
): SearchResultProps => {
  const {
    id,
    title,
    description,
    status,
    queue_id: queueId,
    queue_access_type: queueAccessType,
  } = caseResult;
  return {
    value: id,
    title: `#${id} - ${title}`,
    description,
    icon: <IconBriefcase />,
    label: status,
    url: ROUTES_MAP.casesId.path.replace(':id', String(id)),
    queueId,
    queueAccessType,
  };
};

export const formatRuleTopBarSearch = (
  rule: RulesSearchResult,
): SearchResultProps => {
  const { id, title, description, status } = rule;
  const url =
    status === 'VALIDATION'
      ? ROUTES_MAP.detectionModelsValidationId.path.replace(':id', String(id))
      : ROUTES_MAP.detectionModelsId.path.replace(':id', String(id));
  return {
    value: id,
    title: `#${id} - ${title}`,
    description,
    label: status,
    icon: <IconScale />,
    url,
  };
};

export const formatTransactionOptions = (
  events: (EventsSearchResult | ShortTxnResponse | TransactionSource)[],
): U21SelectOptionProps<number | string>[] =>
  events.map((event) => ({
    icon: <IconCoin />,
    text: event.external_id,
    value: event.external_id,
  }));

export const formatActionEventOptions = (
  actionEvents: (EventsSearchResult | ShortActionEventResponse)[],
  searchType?: string,
  valueField?: ValueField,
): U21SelectOptionProps<number | string>[] => {
  return actionEvents.map((event) => {
    const { id, external_id: externalId } = event;

    const text =
      searchType === 'unit21_id' ? `#${id}` : `#${id} - ${externalId}`;

    return {
      icon: <IconLock />,
      text,
      value: valueField === ValueField.ID ? id : externalId,
    };
  });
};

export const formatInstrumentOptions = (
  instruments: (InstrumentsSearchResult | ShortTxnInstrumentResponse)[],
  searchType?: string,
): U21SelectOptionProps<number | string>[] => {
  return instruments.map((instrument) => {
    const { id, external_id: externalId } = instrument;

    const text =
      searchType === 'unit21_id' ? `#${id}` : `#${id} - ${externalId}`;

    return {
      icon: <IconCreditCard />,
      value: externalId,
      text,
    };
  });
};

export const formatEventTopBarSearch = (
  event: EventsSearchResult,
): SearchResultProps => {
  const { id, external_id: externalId } = event;
  return {
    value: id,
    title: externalId,
    icon: <IconCoin />,
    url: ROUTES_MAP.dataExplorerTransactionsId.path.replace(':id', String(id)),
  };
};

export const formatActionEventsTopBarSearch = (
  event: EventsSearchResult,
): SearchResultProps => {
  const { id, external_id: externalId } = event;
  return {
    value: id,
    title: `#${id} - ${externalId}`,
    icon: <IconLock />,
    url: ROUTES_MAP.dataExplorerActionEventsId.path.replace(':id', String(id)),
  };
};

export const formatInstrumentTopBarSearch = (
  instrument: InstrumentsSearchResult,
): SearchResultProps => {
  const { id, external_id: externalId } = instrument;
  return {
    value: id,
    title: `#${id} - ${externalId}`,
    icon: <IconCreditCard />,
    url: ROUTES_MAP.instrumentsId.path.replace(':id', String(id)),
  };
};

export const stringContainsSubstring = (
  haystack: string,
  needles: string[],
) => {
  return (
    needles.filter((needle) => haystack.includes(needle)).length ===
    needles.length
  );
};

export const mapEntityUnit21IDsToExternalIDs = (
  entityLegacyIds: (string | number)[],
  entitiesSearchResults: EntitiesSearchResult[],
): string[] => {
  const entityUnit21IdToExternalIdMap = entitiesSearchResults.reduce(
    (acc, { id: u21ID, external_id: externalId }) => ({
      ...acc,
      [u21ID]: externalId,
    }),
    {},
  );

  return entityLegacyIds.reduce((externalIds, entityUnit21Id) => {
    const entityExternalId = entityUnit21IdToExternalIdMap[entityUnit21Id];
    if (entityExternalId) {
      return [...externalIds, entityExternalId];
    }
    return externalIds;
  }, []);
};

export function getDisplayName(entity: EntitySource): string {
  if (entity.user_name) {
    return `${entity.user_name}`;
  } else if (entity.first_name && entity.last_name) {
    return `${entity.first_name} ${entity.last_name}`;
  } else if (entity.name) {
    return `${entity.name}`;
  } else if (entity.first_name) {
    return `${entity.first_name}`;
  } else if (entity.last_name) {
    return `${entity.last_name}`;
  }

  return `${entity.external_id}`;
}
