// Models
import { FormFieldCustomSchema, FormFieldSchema } from 'app/shared/models/form';
import { NewCaseDetails } from 'app/modules/cases/models';
import { ValueField } from 'app/modules/search/helpers';

// Selectors
import { selectActiveAgentOptions } from 'app/shared/dropdown/selectors';

// Components
import { U21AgentSelect } from 'app/shared/u21-ui/components/dashboard/U21AgentSelect';
import {
  U21AlertSelect,
  U21EntitySearch,
  U21TransactionEventSearch,
  U21TagSelect,
  U21ActionEventSearch,
} from 'app/shared/u21-ui/components/dashboard';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';
import { U21InstrumentSearch } from 'app/shared/u21-ui/components/dashboard/U21InstrumentSearch';

export interface CaseCreateDetailsFormValue {
  alerts: number[];
  entities: (number | string)[];
  events: (number | string)[];
  action_events: (number | string)[];
  instruments: (number | string)[];
}

export const CASE_TAGS: FormFieldCustomSchema<number[]> = {
  children: <U21TagSelect label={undefined} onChange={() => {}} creatable />,
  label: 'Tags',
  name: 'tags',
  type: 'CUSTOM',
  fieldProps: {
    placeholder: 'Add tags',
  },
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.tags?.map((tag) => tag.id) ?? [],
};

export const CASE_WATCHERS: FormFieldCustomSchema<number[]> = {
  children: (
    <U21AgentSelect
      getPayload={(payload) => ({
        ...payload,
        statuses: ['ACTIVE'],
      })}
      label={undefined}
      multi
      onChange={() => {}}
      selector={selectActiveAgentOptions}
    />
  ),
  fieldProps: {
    placeholder: 'Add agents',
  },
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.watchers?.map((watcher) => watcher.id) ?? [],
  label: 'Watchers',
  name: 'watchers',
  type: 'CUSTOM',
};

export const CASE_ALERTS: FormFieldCustomSchema<number[]> = {
  children: <U21AlertSelect onChange={emptyFn} clearable={false} />,
  label: 'Alerts',
  name: 'alerts' satisfies keyof CaseCreateDetailsFormValue,
  fieldProps: {
    placeholder: 'Add alerts',
    multi: true,
  },
  type: 'CUSTOM',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.alerts?.map((alert) => alert.id) ?? [],
};

export const CASE_ENTITIES: FormFieldCustomSchema<(number | string)[]> = {
  children: (
    <U21EntitySearch
      onChange={() => {}}
      selectProps={{ label: undefined }}
      searchProps={{
        placeholder: 'Search entities by entity ID',
      }}
      valueField={ValueField.EXTERNAL_ID}
      clearable={false}
    />
  ),
  label: 'Entities',
  name: 'entities' satisfies keyof CaseCreateDetailsFormValue,
  type: 'CUSTOM',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.entities?.map((entity) => entity.external_id) ?? [],
};

export const CASE_LINK_ALL_RELATED_ENTITIES: FormFieldSchema<boolean> = {
  name: 'add_related_entities',
  fieldProps: {
    label: `Link all entities from associated alerts to this case`,
  },
  type: U21FormFieldTypes.CHECKBOX,
};

export const CASE_TRANSACTIONS: FormFieldCustomSchema<(number | string)[]> = {
  children: (
    <U21TransactionEventSearch
      onChange={() => {}}
      selectProps={{ label: undefined }}
      searchProps={{
        placeholder: 'Search transactions by unit21 ID (Requires exact match)',
      }}
      clearable={false}
    />
  ),
  label: 'Transaction events',
  name: 'events' satisfies keyof CaseCreateDetailsFormValue,
  type: 'CUSTOM',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.events?.map((event) => event.external_id) ?? [],
};

export const CASE_LINK_ALL_RELATED_TRANSACTIONS: FormFieldSchema<boolean> = {
  name: 'add_related_txn_events',
  fieldProps: {
    label: `Link all transaction events from associated alerts to this case`,
  },
  type: U21FormFieldTypes.CHECKBOX,
};

export const CASE_ACTION_EVENTS: FormFieldCustomSchema<(number | string)[]> = {
  children: (
    <U21ActionEventSearch
      onChange={() => {}}
      selectProps={{ label: undefined }}
      searchProps={{
        placeholder: 'Search action events by unit21 ID (Requires exact match)',
      }}
      valueField={ValueField.EXTERNAL_ID}
      clearable={false}
    />
  ),
  label: 'Action events',
  name: 'action_events' satisfies keyof CaseCreateDetailsFormValue,
  type: 'CUSTOM',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.action_events?.map((event) => event.external_id) ?? [],
};

export const CASE_INSTRUMENTS: FormFieldCustomSchema<(number | string)[]> = {
  children: (
    <U21InstrumentSearch
      onChange={() => {}}
      searchProps={{
        placeholder: 'Search instruments by instrument ID',
      }}
      clearable={false}
    />
  ),
  label: 'Instruments',
  name: 'instruments',
  type: 'CUSTOM',
  default: (caseDetail: NewCaseDetails) =>
    caseDetail?.instruments?.map((instrument) => instrument.external_id) ?? [],
};

export const CASE_DETAILS_SCHEMA = [
  CASE_TAGS,
  CASE_WATCHERS,
  CASE_ALERTS,
  CASE_ENTITIES,
  CASE_TRANSACTIONS,
  CASE_ACTION_EVENTS,
  CASE_INSTRUMENTS,
];
