import {
  FormApi,
  MutableState,
  FormSubscription,
  Config,
  Decorator,
  Mutator,
  SubmissionErrors,
} from 'final-form';

import { HTMLProps, ReactNode, useMemo } from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import arrayMutators from 'final-form-arrays';
import createFocusDecorator from 'final-form-focus';
import createSubmitListenerDecorator from 'final-form-submit-listener';

import { Form as ReactForm, FormRenderProps } from 'react-final-form';
import { U21FormAutoSave } from 'app/shared/u21-ui/components/form/U21FormAutoSave';
import { U21FormContext } from 'app/shared/u21-ui/components/form/U21FormContext';
import { U21LeaveWarning } from 'app/shared/u21-ui/components/display/U21LeaveWarning';
import { FormFieldError } from 'app/shared/models/form';
import { U21FormAPI } from 'app/shared/u21-ui/components';
import { U21Mutators } from 'app/shared/u21-ui/components/form/models';

export interface U21FormProps<FormValues>
  extends Omit<
      HTMLProps<HTMLFormElement>,
      'autoSave' | 'children' | 'onSubmit'
    >,
    Omit<Config<FormValues>, 'onSubmit'> {
  autoSave?: boolean;
  autoSaveDelay?: number;
  children: ReactNode | ((props: FormRenderProps<FormValues>) => ReactNode);
  decorators?: Decorator<FormValues>[];
  disabled?: boolean;
  ignoredRoutes?: (route: string) => boolean;
  keepDirtyOnReinitialize?: boolean;
  loading?: boolean;
  onAfterSubmitFailed?: (form: FormApi<FormValues>) => void;
  onAfterSubmitSucceeded?: (form: FormApi<FormValues>) => void;
  onBeforeSubmit?: (form: FormApi<FormValues>) => false | undefined;
  onSubmit: (
    values: FormValues,
    form: U21FormAPI<FormValues>,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  preventEnterSubmit?: boolean;
  prompt?: boolean;
  skipAutoSaveValidation?: boolean; // Used when we want to save a draft of a form.
  subscription?: FormSubscription;
}

const focusOnError = createFocusDecorator();

// for some reason typing doesn't work unless its spread as an object
const ARRAY_MUTATORS = { ...arrayMutators };

// change the type from FormApi -> U21FormAPI
// mutators type is incompatible so omit it so the type change works
const typeForm = <TFormValues = unknown,>(
  form: Omit<FormApi<TFormValues>, 'mutators'>,
): form is U21FormAPI<TFormValues> => true;

export const U21Form = <FormValues,>({
  autoSave,
  autoSaveDelay,
  children,
  decorators = [],
  disabled = false,
  ignoredRoutes,
  initialValues,
  keepDirtyOnReinitialize = true,
  loading = false,
  onAfterSubmitFailed,
  onAfterSubmitSucceeded,
  onBeforeSubmit,
  onSubmit,
  preventEnterSubmit = false,
  prompt = true,
  skipAutoSaveValidation,
  subscription,
  validate,
  ...rest
}: U21FormProps<FormValues>) => {
  const context = useMemo(
    () => ({ disabled, loading, preventEnterSubmit }),
    [disabled, loading, preventEnterSubmit],
  );

  const submitListener = useMemo(
    () =>
      createSubmitListenerDecorator({
        afterSubmitFailed: onAfterSubmitFailed,
        afterSubmitSucceeded: onAfterSubmitSucceeded,
        beforeSubmit: onBeforeSubmit,
      }),
    [onAfterSubmitFailed, onAfterSubmitSucceeded, onBeforeSubmit],
  );

  const mutators = useMemo<
    Record<U21Mutators, Mutator<FormValues>> & typeof ARRAY_MUTATORS
  >(
    () => ({
      ...ARRAY_MUTATORS,
      setFormTouched: (args: [boolean], state: MutableState<FormValues>) => {
        const [touched = false] = args;
        Object.values(state.fields).forEach((draft) => {
          draft.touched = touched;
        });
      },
      // note: this only works if the form has no validation
      setFieldError: (
        [field, error]: [string, FormFieldError],
        draft: MutableState<FormValues>,
      ) => {
        if (!draft.fields[field]) {
          return;
        }
        if (error && error.length) {
          // if error, add to formState.errors
          if (draft.formState.errors) {
            draft.formState.errors[field] = error;
          } else {
            draft.formState.errors = { [field]: error };
          }
        } else if (draft.formState.errors) {
          // if no error, remove from formState.errors
          delete draft.formState.errors[field];
        }
      },
      setFieldValue: <TKey extends keyof FormValues>(
        [field, value]: [TKey, FormValues[TKey]],
        state: MutableState<FormValues>,
        { changeValue },
      ) => {
        changeValue(state, field, () => value);
      },
      setFieldValueUntyped: (
        [field, value]: [string, any],
        state: MutableState<FormValues>,
        { changeValue },
      ) => {
        changeValue(state, field, () => value);
      },
      setFieldTouched: (
        [field, touched]: [Extract<keyof FormValues, string>, boolean],
        draft: MutableState<FormValues>,
      ) => {
        if (draft.fields[field]) {
          draft.fields[field].touched = touched;
        }
      },
      setFieldTouchedUntyped: (
        [field, touched]: [string, boolean],
        draft: MutableState<FormValues>,
      ) => {
        if (draft.fields[field]) {
          draft.fields[field].touched = touched;
        }
      },
      setFormLoading: (
        [isFormLoading]: [boolean],
        draft: MutableState<FormValues>,
      ) => {
        draft.formState.submitting = isFormLoading;
      },
    }),
    [],
  );

  return (
    <ReactForm<FormValues>
      initialValues={initialValues}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      mutators={mutators}
      decorators={[focusOnError, submitListener, ...decorators]}
      onSubmit={async (values, form, callback) => {
        // workaround to get the form from FormApi -> U21FormApi
        if (typeForm(form)) {
          return onSubmit(values, form, callback);
        }
        return undefined;
      }}
      render={(formProps) => {
        const { dirty, handleSubmit, submitSucceeded, dirtySinceLastSubmit } =
          formProps;
        return (
          <U21FormContext.Provider value={context}>
            <form
              aria-label="form"
              onSubmit={handleSubmit}
              {...getDOMProps(rest)}
            >
              {autoSave && (
                <U21FormAutoSave<FormValues>
                  delay={autoSaveDelay}
                  onSubmit={onSubmit}
                  skipAutoSaveValidation={skipAutoSaveValidation}
                />
              )}
              {typeof children === 'function' ? children(formProps) : children}
            </form>
            {prompt && (
              <U21LeaveWarning
                ignoredRoutes={ignoredRoutes}
                when={
                  submitSucceeded && keepDirtyOnReinitialize
                    ? dirtySinceLastSubmit
                    : dirty
                }
              />
            )}
          </U21FormContext.Provider>
        );
      }}
      subscription={
        typeof subscription !== 'undefined' && prompt
          ? {
              dirty: true,
              dirtySinceLastSubmit: true,
              submitSucceeded: true,
              ...subscription,
            }
          : undefined
      }
      validate={validate}
    />
  );
};
