// Models
import { EntitiesActionTypes } from 'app/modules/entities/models';

// Constants
import { initialPaginatedEntityAlerts } from 'app/modules/entities/reducer';

// Constants
import { EMPTY_ENTITY } from 'app/modules/entities/constants';

export const selectEntity = (state: RootState) => {
  return state.entities.details || EMPTY_ENTITY;
};

export const selectPaginatedEntityAlerts = (state: RootState) => {
  return (
    state.entities.details?.paginated_alerts || {
      ...initialPaginatedEntityAlerts,
    }
  );
};

export const selectRetrieveEntityLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.RETRIEVE_ENTITY]);
};

export const selectEditEntityLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.EDIT_ENTITY]);
};

export const selectEntityAlertsLoading = (state: RootState): boolean => {
  return Boolean(state.loading[EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS]);
};
